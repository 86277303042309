import axios from "axios";
import $ from "jquery";
import fbLogo from "../../assets/images/facebook.png";
import ytLogo from "../../assets/images/youtube.png";
import tweetLogo from "../../assets/images/twitter.png";
import instaLogo from "../../assets/images/instagram.png";
import lnLogo from "../../assets/images/linkedin.png";
import otherImg from "../../assets/images/otherImg.png";
import moment from "moment";

let lastScroll = 0;

export const getLogo = (type) => {
  let logo = otherImg;
  switch (type) {
    case "Facebook":
      logo = fbLogo;
      break;
    case "Youtube":
      logo = ytLogo;
      break;
    case "Twitter":
      logo = tweetLogo;
      break;
    case "Instagram":
      logo = instaLogo;
      break;
    case "Linkedin":
      logo = lnLogo;
      break;
    default:
      logo = otherImg;
  }
  return logo;
};

export const getFileExt = (fileName) => {
  const mtype = fileName.split(".");
  const ext = mtype[mtype.length - 1];
  return ext;
};

export function clearUserItemsFromLocalStorage() {
  localStorage.removeItem("auth_params");
  localStorage.removeItem("constants");
}

export function ShowMsg(form, type, msg) {
  const alert = $(
    '<div class="alert alert-' +
      type +
      ' alert-dismissible" role="alert"><div class="alert-text">' +
      msg +
      '</div><div class="alert-close" ><i class="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i></div></div>'
  );

  form.find(".alert").remove();
  alert.prependTo(form);
  alert.find("span").html(msg);
}

export const RemoveMsg = (form) => form.find(".alert").remove();

export function StartProcessing(btn) {
  btn
    .addClass("kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light")
    .attr("disabled", true);
}

export function StopProcessing(btn) {
  btn
    .removeClass(
      "kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light"
    )
    .attr("disabled", false);
}

export const showLoginModal = (clearLocalStorage = true) => {
  if (clearLocalStorage) clearUserItemsFromLocalStorage();
  $("#login-modal-backdrop").addClass("show");
  $("#login-modal").addClass("show");
};

export const AddAssetModal = (which) => {
  $(`#add-${which}-bd`).addClass("show");
  $(`#add-${which}-modal`).addClass("show");
};

export function SetScrollHeight() {
  const windowHeight = $(window).height();
  let headerHeight = 0;
  let footerHeight = 0;
  let navHeight = 0;
  let stickyFilterHeight = 0;
  let tableHeadHeight = 0;

  if (
    $("#kt_header").height() !== null &&
    $("#kt_header").height() !== undefined
  ) {
    headerHeight = $("#kt_header").height();
  }

  if (
    $("#kt_footer").height() !== null &&
    $("#kt_footer").height() !== undefined
  ) {
    footerHeight = $("#kt_footer").height();
  }

  if (
    $(".kt-portlet__head").height() !== null &&
    $(".kt-portlet__head").height() !== undefined &&
    !$(".kt-portlet__head").hasClass("scroll-down")
  ) {
    navHeight = $(".kt-portlet__head").height() + 25;
  }

  if (
    $(".stickyFilter").height() !== null &&
    $(".stickyFilter").height() !== undefined &&
    !$(".stickyFilter").hasClass("scroll-down")
  ) {
    stickyFilterHeight = $(".stickyFilter").height();
  }

  if (
    $(".kt-datatable__head").height() !== null &&
    $(".kt-datatable__head").height() !== undefined
  ) {
    tableHeadHeight = $(".kt-datatable__head").height();
  }

  const totalReduceHeight =
    headerHeight +
    footerHeight +
    navHeight +
    stickyFilterHeight +
    tableHeadHeight +
    120;
  const scrollHeight = windowHeight - totalReduceHeight;

  $(".scrolTable").css("max-height", `${scrollHeight}px`);
}

export function setStickyHeader() {
  const container = $(".scrolTable");
  const header = $(".kt-portlet__head");
  const filter = $(".stickyFilter");

  const currentScroll = container.scrollTop();
  if (currentScroll <= 0) {
    header.removeClass("scroll-up");
    filter.removeClass("scroll-up");
    header.removeClass("scroll-down");
    filter.removeClass("scroll-down");
    return;
  }

  if (currentScroll > lastScroll + 10 && !header.hasClass("scroll-down")) {
    // down
    header.removeClass("scroll-up");
    header.addClass("scroll-down");
    filter.removeClass("scroll-up");
    filter.addClass("scroll-down");
  } else if (currentScroll < lastScroll && header.hasClass("scroll-down")) {
    // up
    header.addClass("scroll-up");
    header.removeClass("scroll-down");
    filter.addClass("scroll-up");
    filter.removeClass("scroll-down");
  }
  lastScroll = currentScroll;
  SetScrollHeight();
}

export const getErrorMessage = (err) =>
  !!err && !!err.response && !!err.response.data && !!err.response.data.message
    ? err.response.data.message
    : // : !!err.message
      //     ? err.message
      "Unknown error";

export const titleCase = (data) =>
  !data || data === ""
    ? ""
    : data
        .split(" ")
        .map((d) => capitalize(d))
        .join(" ");

export const capitalizeCamel = (name) =>
!name || name === ''
  ? ''
  : `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`;

export const capitalize = (name) =>
  !name || name === "" ? "" : `${name[0].toUpperCase()}${name.slice(1)}`;

export const camelCase = (data) =>
!data || data === ''
  ? ''
  : data
      .split(' ')
      .map((d) => capitalizeCamel(d))
      .join(' ');

      
export const getServerTimestamp = () => axios.get("/api/data/server-timestamp");


export const getFormatedDateWithStatus = (date) => {

  const refDate = moment(date);

  const today        = refDate.isSame(moment().startOf('day'), 'd');
  const yesterday    = refDate.isSame(moment().subtract(1, 'days').startOf('day'), 'd');
  const days = moment().diff(refDate, 'days');
  
  if (today)
    return { dateString :`Today`, badgeColor : 'success text-white', status:'Active' }
  else if(yesterday)
    return { dateString :`Yesterday`, badgeColor : 'success text-white', status:'Active' }
  else if(days <= 7 )
    return { dateString :`${days} days ago`, badgeColor : 'warning', status:'Moderate' }
  else if(days > 7 && days <= 15 )
    return { dateString : `${days} days ago`, badgeColor : 'secondary', status:'Cold' }
  else
    return { dateString : refDate.format('DD MMM YYYY') , badgeColor : 'danger text-white', status:'Inactive' }
}

export const getSubscriptionStatus = (date) => {

  const refDate = moment(date);
  let days      = moment().diff(refDate, 'days');
  const today   = refDate.isSame(moment().startOf('day'), 'd');

  if( days < -7){
    return { dateString :'Active',  badgeColor : 'success text-white', status:'Active' }
  } else if(days >= -7 && days < 0){
    days = days.toString().split('-')[1] 
    return { dateString :`${days} days to expiry`, badgeColor : 'warning', status:'Active' }
  }else if(today){
    return { dateString :`Expiring Today`, badgeColor : 'warning', status:'Active' }
  }else if(days == 0){
    return { dateString :`Expiring Tomorrow`, badgeColor : 'warning', status:'Active' }
  }else if(days > 0 && days <= 7){
    return { dateString :`Expired ${days} days ago`, badgeColor : 'secondary text-danger', status:'Expired' }
  }else{
    return { dateString : 'Expired', badgeColor : 'secondary text-danger', status:'Expired' }
  }
  
}