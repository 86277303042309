import cookie from "react-cookies";
import {sessionSecret} from "../../env.json";
import {Encryptor} from "../../components/global/encryptor";
import moment from 'moment';

const {encrypt, decrypt} = Encryptor(sessionSecret);

export const getAuthState = form => {
    const userCookie = cookie.load('ibConsole')
    if (!userCookie) return false
    const params = JSON.parse(decrypt(userCookie))

    // Check for 5 minutes before expiry 
    if(moment(params.expiresOn).subtract(3, 'second').diff(moment()) < 0) return 'Expired'

    if (form === 'bool') return !!params.token
    if (!!params[form]) return params[form]
    else return params
}

export const setAuthState = (authParams, isRemember )=> {
    if (!authParams) {
        cookie.remove('ibConsole');
    } else {
        //Encrypt Data
        const encryptedData = encrypt(JSON.stringify(authParams));
        //FOR Cookie Age 30Days set maxAge:2592000 seconds 
        let cookieOptions =!isRemember? { path : '/', secure : true } : { path : '/', maxAge : 2592000, secure : true };
        cookie.save('ibConsole', encryptedData, cookieOptions);
    }
}
