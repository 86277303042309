import React,{ useState, useEffect} from "react";
import Loading  from "../global/loading";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import swal from "sweetalert2";
import $ from "jquery";
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import * as helper from "../global/helper";
import SendEmailModal from "./sendEmailModal";
import EmailTemplateTable from "./emailTemplateTable";
import EmailStatTable from "./emailStatsTable";
import { getEmailTemplates, addEmailTemplate, updateEmailTemplate, deleteEmailTemplate } from "../api/emailerAPI";

const AddTemplateModal = (props) => {
    const { selectedTemplate , setSelectedTemplate } = props;

    const [templateData, setTemplateData] = useState(selectedTemplate || {});

    useEffect(() => {
        $('#addTemplate-modal').modal({ backdrop: 'static' });

        window.initCkeditor("#editorContent");
  
        $('#addTemplate-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');   
          window.removeCkeditor("editorContent");
          props.refetchTemplates(true);
          setSelectedTemplate(null);
          props.onDismissModal(); 
        });
    }, []);

    useEffect(() => {
        if(selectedTemplate && selectedTemplate.Template) window.setEditorValue("#editorContent", selectedTemplate.Template);
    },[selectedTemplate])

    const handleInput = (key, value) => {
        setTemplateData( d  => { return {...d, [key]: value}})
    }

    const handleSubmit = async(e) => {
        e.preventDefault();

        const Content = window.getEditorValue("#editorContent");
        var form = $("#addTemplate-form");
        form.validate().destroy();
        
        form.validate({
            rules: {
              Name: { required: true },
            },
        });

        if (!form.valid())  return;

        if (!Content) {
            return swal.fire({
              icon  : "error",
              title : "Template Content is required !",
            });
        }

        helper.StartProcessing($("#save-btn"));
        try {
            if(selectedTemplate && selectedTemplate.ID){
                let newData = templateData;
                newData.Template = Content;
                updateEmailTemplate(newData);
                swal.fire({
                    icon                : 'success',
                    titleText           : 'Template Saved',
                    showConfirmButton   : false,
                    timer               : 1500,
                    toast               : true,
                    position            :'bottom-end'
                });
            }else{
                addEmailTemplate({...templateData, Template : Content});
                swal.fire({
                    icon                : 'success',
                    titleText           : 'Template Added Successfully',
                    showConfirmButton   : false,
                    timer               : 1500,
                    toast               : true,
                    position            :'bottom-end'
                });
            }

            helper.StopProcessing($("#save-btn"));
            $("#addTemplate-modal").modal("hide");
        } catch (err) {
            helper.StopProcessing($("#save-btn"));
            swal.fire({
              icon                : 'error',
              titleText           : 'Error!',
              text                : err.message,
              buttonsStyling      : false,
              confirmButtonClass  : 'btn btn-brand',
            });  
        }
    }

    return(
        <div className="modal fade" id="addTemplate-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title"> {selectedTemplate ? 'Edit': 'Add'} Email Template </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"> </button>
                </div>
                <div className="modal-body">
                    <div className="client-container">
                        <form id="addTemplate-form" onSubmit={(e) => {  e.preventDefault()}}>
                            <div className="row">
                                <div className="col-12">
                                <input
                                    id="Name"
                                    value={templateData.Name || ''}
                                    name="Name"
                                    placeholder="Name"
                                    className="form-control margin-b10"
                                    type="text"
                                    onChange={({ target: { value } }) => handleInput('Name', helper.camelCase(value)) }/>          
                                </div>
                            </div>
                        
                            {/* Content- CkEditor */}
                            <div className="row margin-t20">
                                <div className="col-12">
                                    <textarea name="editorContent" id="editorContent" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary margin-0 margin-r5"
                        data-dismiss="modal">   
                        Close
                    </button>
                    <button
                        id="save-btn"
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-brand margin-0">   
                        {selectedTemplate ? 'Save' : 'Add'} Template
                    </button>
                </div>
            </div>
            </div>
        </div>
    )   
}

const Emailer = (props) => {

    const { params }                                    = useRouteMatch();
    const [Templates,            setTemplates]          = useState([]);
    const [loading,              setLoading]            = useState(false);
    const [showAddTemplateModal, setAddTemplateModal]   = useState(false); 
    const [selectedTemplate,     setSelectedTemplate]   = useState(null);
    const [sendEmailTemplate,    setSendEmaiTemplate]   = useState(null); 
    const [refetch,              setRefetch]            = useState(false);

    useEffect(() => {
        let ignore = false;
      
        if(!ignore) {
            props.title('Emailer', 'menuEmailer');
            fetchEmailTemplates();
        }
        return () => {
            ignore = true;
        }
    },[])

    useEffect(() => {
        if(refetch){
            fetchEmailTemplates()
            setRefetch(false)
        }
    },[refetch])
    
    const fetchEmailTemplates = async() =>{
        setLoading(true)
        try {
            const response = await getEmailTemplates();
            if(response) {
                setTemplates(response); 
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });    
        }
    } 

    const onEditHandler = (data) => {
        setSelectedTemplate(data)
        setAddTemplateModal(true);
    }

    const onDeleteHandler = (data) => {
        swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3758ff',
            confirmButtonText: 'Yes, delete it!'
        }).then(async(result) => {
            if (result.isConfirmed) {
                try {
                    const response = await deleteEmailTemplate(data.ID);
                    
                    if(response) {
                        const newArr = Templates.filter( t => t.ID != data.ID);
                        setTemplates(newArr);

                        swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    }
                } catch (err) {
                    swal.fire({
                        icon                : 'error',
                        titleText           : 'Error!',
                        text                : err.message,
                        buttonsStyling      : false,
                        confirmButtonClass  : 'btn btn-brand',
                    });    
                }
            }
        })
    }

    const onSendHandler = (data) => { setSendEmaiTemplate(data) };

    return(<>
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
            <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                    <div className="kt-portlet__head-label">
                        <nav className="file-nav navbar navbar-top">
                            <NavLink activeClassName="top-nav-active" to="/emailer/templates">
                            Emailer
                            </NavLink>
                        </nav>
                        <nav className="file-nav navbar navbar-top">
                            <NavLink activeClassName="top-nav-active" to="/emailer/emailStats">
                                Email Stats
                            </NavLink>
                        </nav>
                    </div>
                    {params && params.type === 'templates' && (  
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions margin-l10">
                                    <button className="btn btn-brand btn-icon-sm text-white" onClick={() => setAddTemplateModal(true)}>
                                        <i className="la la-plus"></i>New Template
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="kt-portlet__body kt-portlet__body--fit">
                    <Switch>
                        <Route path ='/emailer/templates'>
                            <EmailTemplateTable
                                loading         = {loading}
                                Templates       = {Templates}
                                onEditHandler   = {onEditHandler}
                                onDeleteHandler = {onDeleteHandler}
                                onSendHandler   = {onSendHandler}
                            />
                        </Route>

                        <Route path='/emailer/emailStats'>
                            <EmailStatTable />
                        </Route>

                    </Switch>
                </div>
            </div>
        </div>
        {showAddTemplateModal && (
            <AddTemplateModal 
                onDismissModal={() => setAddTemplateModal(false)}
                refetchTemplates = {(v) => setRefetch(v)}
                selectedTemplate = { selectedTemplate }
                setSelectedTemplate= { setSelectedTemplate }
            />
        )}
        {sendEmailTemplate && (
            <SendEmailModal
                onDismissModal = {() => setSendEmaiTemplate(null)}
                selectedEmailTemplate = { sendEmailTemplate }
            />
        )}
    </>)
}

export default Emailer;