import React,{ useEffect, useState } from 'react';
import "./appActions.css"
import swal from 'sweetalert2';
import * as helper from "../global/helper";
import Loading  from "../global/loading";
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/bootstrap-modal";
import "../../assets/scripts/jquery-validation.init";
import { getAgentAppUpdateAndAppService, updateAgentAppUpdate, updateAgentAppService } from '../api/agentsAPI';


const AppActions = (props) =>{

    const [nav,               setNav]               = useState('appUpdate');
    const [appUpdateIOS,      setAppUpdateIOS]      = useState({});
    const [appUpdateAndroid,  setAppUpdateAndroid]  = useState({});
    const [appServiceIOS,     setAppServiceIOS]     = useState({});
    const [appServiceAndroid, setAppServiceAndroid] = useState({});
    const [loading,           setLoading]           = useState(false);

    useEffect(() => {
        let ignore = false;
        if(!ignore){
            fetchAppDetails();
            props.title('App Actions', 'menuAppActions');
        }
        return () => {
            ignore = true;
        }
    }, []);
    
    const fetchAppDetails = async() => {
        try {
            setLoading(true);
            const response = await getAgentAppUpdateAndAppService();
            if(response){
                const {appService, appUpdate} = response;
                for(const data of appUpdate){
                    if(data.Type == 'Android'){
                        setAppUpdateAndroid(data);
                    }else{
                        setAppUpdateIOS(data);
                    }
                }
                for(const data of appService){
                    if(data.Type == 'Android'){
                        setAppServiceAndroid(data);
                    }else{
                        setAppServiceIOS(data);
                    }
                }
                setLoading(false);
            }
        } catch (err) {
            swal.fire({
                icon: "error",
                titleText: "Error!",
                text: err.message,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-brand",
            });
            setLoading(false);
        }
    }

    const androidDataHandler = ( key, value, type ) => {
        if(type == 'appService'){
            setAppServiceAndroid( d => { return {...d, [key] : value} });
        }else{
            setAppUpdateAndroid( d => { return {...d, [key] : value} });
        }
    }

    const iosDataHandler = ( key, value, type ) => {
        if(type == 'appService'){
            setAppServiceIOS( d => { return {...d, [key] : value} });
        }else{
            setAppUpdateIOS( d => { return {...d, [key] : value} });
        }
    }

    const radioChangeHandler = ( value, key ) => {
        switch(key){
            case 'iOSAppService':
                iosDataHandler('IsRunning', value, 'appService');
            break;
            case 'androidAppService':
                androidDataHandler('IsRunning', value, 'appService');
            break;
            case 'iOSAppUpdate':
                iosDataHandler('IsMandatory', value);
            break;
            default :
                androidDataHandler('IsMandatory', value);
            break
        }
    }

    const onUpdateSaveHandler = async(e, type, btnId) => {
        e.preventDefault();
        const btn = $(`#${btnId}`)
        try {
            let form 
            helper.StartProcessing(btn);
            if(type == 'AndroidAppUpdate') 
                form = $('#form-appUpdateAndroid')
            else form = $('#form-appUpdateIOS');
            
            form.validate({
                rules: {
                  CurrentVersion: {
                    required: true,
                  },
                  IsMandatory: {
                    required: true,
                  },
                },
            });

            if (!form.valid()) return;

            let data ;
            if(type == 'AndroidAppUpdate') 
                data = appUpdateAndroid
            else data = appUpdateIOS;
            
            if(data) {
                await updateAgentAppUpdate(data);
                swal.fire({
                    icon                :'success',
                    titleText           :'Agent App Updated',
                    showConfirmButton   : false,
                    timer               : 1500,
                    toast               : true,
                    position            :'bottom-end'
                });
                helper.StopProcessing(btn);
            }   
        } catch (err) {
            swal.fire({
                icon: "error",
                titleText: "Error!",
                text: err.message,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-brand",
            });

            helper.StopProcessing(btn);
        }
    }

    const onServiceSaveHandler = async (e, type, btnId) => {
        e.preventDefault();
        const btn = $(`#${btnId}`)
        try {
            let form 
            helper.StartProcessing(btn);
            if(type == 'androidAppService') 
                form = $('#form-appServiceAndroid')
            else form = $('#form-appServiceIOS');
            
            form.validate({
                rules: {
                  Message: {
                    required: true,
                  },
                  IsRunning: {
                    required: true,
                  },
                },
            });

            if (!form.valid()) return;

            let data ;
            if(type == 'androidAppService') 
                data = appServiceAndroid
            else data = appServiceIOS;
            
            if(data) {
                await updateAgentAppService(data);
                swal.fire({
                    icon                :'success',
                    titleText           :'Agent App Service Updated',
                    showConfirmButton   : false,
                    timer               : 1500,
                    toast               : true,
                    position            :'bottom-end'
                });
                helper.StopProcessing(btn);
            }   

        } catch (err) {
            swal.fire({
                icon: "error",
                titleText: "Error!",
                text: err.message,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-brand",
            });

            helper.StopProcessing(btn);
        }
    }


    const navToggle = (nav) => {
        $('.navbar-item').removeClass('active-tab');
        $(`#${nav}`).addClass('active-tab');
        setNav(nav)  
    }
    
    return(
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <span id='appUpdate' className="navbar-item active-tab" onClick={() => navToggle('appUpdate')}>         
                                    Agent App Update
                                </span>
                                <span id='appService' className="navbar-item"  onClick={() => navToggle('appService')}>
                                    Agent App Service
                                </span>
                            </nav>
                        </div>
                        
                    </div>
                    {loading ? (
                        <Loading />
                    ) : <div className="kt-portlet__body">
                            {nav == 'appUpdate' && (
                                <div className="kt-portlet__body">
                                    <div className="kt-section mb-0">
                                        <h3 className="kt-section__title">Android:</h3>
                                        
                                        <form className="kt-form kt-form--label-right" id="form-appUpdateAndroid">
                                            <div className="kt-section__body">
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">Current version:</label>
                                                    <div className="col-lg-6">
                                                        <input 
                                                            type = "number" 
                                                            name = "CurrentVersion"
                                                            className = "form-control" 
                                                            placeholder = "Current version" 
                                                            min = {1}
                                                            value = {appUpdateAndroid.CurrentVersion || ''}
                                                            onChange = {({target}) => androidDataHandler('CurrentVersion', Math.floor(target.value)) }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">Is Mandatory:</label>
                                                    <div className="col-lg-6">
                                                        <div className="kt-radio-inline">
                                                            <label className="kt-radio kt-radio--solid">
                                                                <input 
                                                                    type="radio" 
                                                                    name = "IsMandatory"      
                                                                    value="Y" 
                                                                    checked = {appUpdateAndroid.IsMandatory == 'Y'}  
                                                                    onChange={({target})=> radioChangeHandler(target.value, 'androidAppUpdate') } 
                                                                /> 
                                                                    Yes
                                                                <span></span>
                                                            </label>
                                                            <label className="kt-radio kt-radio--solid">
                                                                <input 
                                                                    type="radio" 
                                                                    name="IsMandatory" 
                                                                    value="N" 
                                                                    checked = {appUpdateAndroid.IsMandatory == 'N'}  
                                                                    onChange={({target})=> radioChangeHandler(target.value, 'androidAppUpdate') } 
                                                                /> No
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="kt-form__actions ">
                                                <div className='row'>
                                                    <div className='col-3'></div>
                                                    <div className='col-9'>
                                                        <button className="btn btn-brand" id='androidAppUpdate-btn' onClick={(e) => onUpdateSaveHandler(e, 'AndroidAppUpdate', 'androidAppUpdate-btn')}  >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
                                        <h3 className="kt-section__title">iOS:</h3>

                                        <form className="kt-form kt-form--label-right" id="form-appUpdateIOS">
                                            <div className="kt-section__body">
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">Current version:</label>
                                                    <div className="col-lg-6">
                                                        <input 
                                                            type = "number" 
                                                            name = "CurrentVersion" 
                                                            className = "form-control" 
                                                            placeholder = "Current version" 
                                                            min = {1}
                                                            value = {appUpdateIOS.CurrentVersion || ''}
                                                            onChange = {({target}) => iosDataHandler('CurrentVersion', Math.floor(target.value)) }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">Is Mandatory:</label>
                                                    <div className="col-lg-6">
                                                        <div className="kt-radio-inline">
                                                            <label className="kt-radio kt-radio--solid">
                                                                <input 
                                                                    type="radio" 
                                                                    name="IsMandatory" 
                                                                    value="Y"
                                                                    checked = {appUpdateIOS.IsMandatory == 'Y'}  
                                                                    onChange={({target})=> radioChangeHandler(target.value, 'iOSAppUpdate') } 
                                                                /> Yes
                                                                <span></span>
                                                            </label>
                                                            <label className="kt-radio kt-radio--solid">
                                                                <input 
                                                                    type="radio" 
                                                                    name="IsMandatory" 
                                                                    value="N" 
                                                                    checked = {appUpdateIOS.IsMandatory == 'N'}  
                                                                    onChange={({target})=> radioChangeHandler(target.value, 'iOSAppUpdate') } 
                                                                /> No
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="kt-form__actions">
                                                <div className='row'>
                                                    <div className='col-3'></div>
                                                    <div className='col-9'>
                                                        <button className="btn btn-brand" id='iosAppUpdate-btn' onClick={(e) => onUpdateSaveHandler(e, 'iosAppUpdate', 'iosAppUpdate-btn')} >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                            {nav == 'appService' && (
                                <div className="kt-portlet__body">
                                    <div className="kt-section mb-0">
                                        <h3 className="kt-section__title">Android:</h3>
                                        
                                        <form className="kt-form kt-form--label-right" id="form-appServiceAndroid">
                                            <div className="kt-section__body">
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label"> Message:</label>
                                                    <div className="col-lg-6">
                                                    <textarea 
                                                        rows="3"
                                                        className="form-control" 
                                                        name="Message" 
                                                        placeholder="Message" 
                                                        value = {appServiceAndroid.Message || ''}
                                                        onChange ={({target}) => androidDataHandler('Message', target.value, 'appService') }
                                                    ></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">Is Running:</label>
                                                    <div className="col-lg-6">
                                                        <div className="kt-radio-inline">
                                                            <label className="kt-radio kt-radio--solid">
                                                                <input 
                                                                    type="radio"
                                                                    name = "IsRunning"  
                                                                    value="Y" 
                                                                    checked = {appServiceAndroid.IsRunning =='Y'}  
                                                                    onChange={({target})=> radioChangeHandler(target.value, 'androidAppService') } 
                                                                /> Yes
                                                                <span></span>
                                                            </label>
                                                            <label className="kt-radio kt-radio--solid">
                                                                <input 
                                                                    type="radio" 
                                                                    name="IsRunning" 
                                                                    value="N" 
                                                                    checked = {appServiceAndroid.IsRunning =='N'}  
                                                                    onChange={({target})=> radioChangeHandler(target.value, 'androidAppService') } 
                                                                /> No
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="kt-form__actions ">
                                                <div className='row'>
                                                    <div className='col-3'></div>
                                                    <div className='col-9'>
                                                        <button className="btn btn-brand" id='androidAppService-btn' onClick={(e) => onServiceSaveHandler(e, 'androidAppService', 'androidAppService-btn')} >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg"></div>
                                        <h3 className="kt-section__title">iOS:</h3>

                                        <form className="kt-form kt-form--label-right" id="form-appServiceIOS">
                                            <div className="kt-section__body">
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">Message:</label>
                                                    <div className="col-lg-6">
                                                    <textarea 
                                                        rows="3"
                                                        className="form-control"  
                                                        name="Message"                        
                                                        placeholder="Message" 
                                                        value = {appServiceIOS.Message || ''}
                                                        onChange ={({target}) => iosDataHandler('Message', target.value, 'appService') }
                                                    ></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">Is Running:</label>
                                                    <div className="col-lg-6">
                                                        <div className="kt-radio-inline">
                                                            <label className="kt-radio kt-radio--solid">
                                                                <input 
                                                                    type="radio" 
                                                                    name="IsRunning" 
                                                                    value="Y" 
                                                                    checked = {appServiceIOS.IsRunning =='Y'}  
                                                                    onChange={({target})=> radioChangeHandler(target.value, 'iOSAppService') } 
                                                                /> Yes
                                                                <span></span>
                                                            </label>
                                                            <label className="kt-radio kt-radio--solid">
                                                                <input 
                                                                    type="radio" 
                                                                    name="IsRunning" 
                                                                    value="N" 
                                                                    checked = {appServiceIOS.IsRunning =='N'}  
                                                                    onChange={({target})=> radioChangeHandler(target.value, 'iOSAppService') } 
                                                                /> No
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="kt-form__actions">
                                                <div className='row'>
                                                    <div className='col-3'></div>
                                                    <div className='col-9'>
                                                        <button className="btn btn-brand " id='iOSAppService-btn' onClick={(e) => onServiceSaveHandler(e, 'iOSAppService', 'iOSAppService-btn')} >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    }   
                </div>        
            </div>
        </>
    )
}

export default AppActions