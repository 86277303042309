import axios from "axios";
import swal from "sweetalert2";
import { setAuthState } from "../../assets/scripts/login-util";

const errorLogs = ['Authentication failed', 'Authetication Error'];

const getErrorMessage = (err) =>{
  if(err.response && err.response.data){
    if(errorLogs.includes(err.response.data.message)){
      swal.fire({
        icon               : 'warning',
        titleText          : 'Session Expired',
        text               : 'Kindly Login',
        timer              : 2000,
        showConfirmButton  : false,
      });
      setAuthState();
      window.location.reload();
    }
    return err.response.data.message
  }else{
    return 'Unknown error';
  }
}
 

export const axiosPost = async (url, data, config) => {
  try {
    const { data: response } = await axios.post(url, data, config);
    return response;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || "Unknown error");
  }
};

export const axiosGet = async (url, config) => {
  try {
    const { data } = await axios.get(url, config);
    return data;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || "Unknown error");
  }
};

export const axiosPatch = async (url, data, config) => {
  try {
    const { data: response } = await axios.patch(url, data, config);
    return response;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || "Unknown error");
  }
};

export const axiosDelete = async (url, config) => {
  try {
    const { data: response } = await axios.delete(url, config);
    return response;
  } catch (err) {
    const message = getErrorMessage(err);
    throw new Error(message || err.message || "Unknown error");
  }
};
