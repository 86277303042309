import React, { useState, useEffect } from "react";
import * as helper                    from "../global/helper";
import moment                         from "moment";
import RightSideDetails from "../global/rightSideDetails";
import noClient from "../../assets/images/no-client.svg";
import CustomEditor from '../global/editor';
import "jquery-form";
import "jquery-validation";
import "../../assets/scripts/jquery-validation.init";
import swal from 'sweetalert2';
import { getAuthState } from "../../assets/scripts/login-util";
import { updateTempSubscription } from '../api/subscriptionAPI';


const SubscriptionTable = (props) => {
    const { AllSubscribers, refetchSubscription } = props;
    
    const user = getAuthState('user');
    const [allSubscribers,     setAllSubscribers]     = useState([]);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const [subscriberData,     setSubscriberData]     = useState({});
    const [currentStatus,      setCurrentStatus]      = useState('All');
    const [searchTerm,     setSearchTerm]             = useState('');
    
    useEffect(()=>{
      if(selectedSubscriber) setSubscriberData(selectedSubscriber);
    },[selectedSubscriber]);
    
    useEffect(()=>{
      if(AllSubscribers.length > 0) setAllSubscribers(AllSubscribers);
    },[AllSubscribers]);

    useEffect(()=>{
      helper.SetScrollHeight();
    });

    useEffect(() => {
      filterUsers();
    }, [currentStatus]);

    useEffect(() => {
      if(searchTerm == ''){
        setCurrentStatus('All')
        return setAllSubscribers(props.AllSubscribers);
      }

      filterUsers();
    }, [searchTerm]);

    const filterUsers = () => {
      let allUsers  = AllSubscribers;

      if(currentStatus && currentStatus != 'All') allUsers = allUsers.filter( a => helper.getSubscriptionStatus(a.ExpiryDate).status === currentStatus);
      setAllSubscribers(allUsers);

      if(searchTerm != ''){
        const matchedData = allUsers.filter(c => {
          let matchString = `${c.AgentCompanyName} ${c.Email} ${c.Phone} ${moment(c.CreatedDate).format("DD/MM/YYYY hh:mm A")}`;
          let matchFound = 0;
    
          const searchArr = searchTerm.split(' ');
          searchArr.forEach(term => {
            matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0 ;
          });
    
          return matchFound === searchArr.length;
        })
        setAllSubscribers(matchedData);
      }
    }

    const onUpdateSubscription = async(e) => {
      e.preventDefault()
      try {
        const btn = $('#updateSubs-btn');
        const form = $('#form-subscriptionUpdate');
        helper.StartProcessing(btn);
        form.validate({
          rules:{
            startDate:{
              required: true,
            },
            expDate:{
              required: true,
            },
          }
        });
  
        if (!form.valid()) return;
        
        if(moment(subscriberData.StartDate).isSameOrAfter(moment(subscriberData.ExpiryDate).startOf('day'), 'd m y') ){
          swal.fire({
            icon:'error',
            titleText:'Invalid Subscription Dates',
          })
          helper.StopProcessing(btn);
          return;
        }

        await updateTempSubscription(subscriberData);
        swal.fire({
          icon                :'success',
          titleText           :'Subscription Updated',
          showConfirmButton   : false,
          timer               : 1700,
          toast               : true,
          position            :'bottom-end'
        });
        closeDrawerHandler();
        refetchSubscription(true)
        helper.StopProcessing(btn);
      } catch (err) {
        swal.fire({
          icon: "error",
          titleText: "Error!",
          text: err.message,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-brand",
        });
        helper.StopProcessing(btn);
      }
    }

    const onEditHandler = (key, value) => {
      setSubscriberData(s => ({...s, [key] : value }));
    }

    const selectUserHandler = async (u) => {
      setSelectedSubscriber({...u});
    } 

    const closeDrawerHandler = () => {
      setTimeout(() => setSelectedSubscriber(null), 180);
    };
    
    return (
    <div className="kt-form kt-form--label-right kt-margin-b-10">

      <div className="row align-items-center stickyFilter">
        <div className="col-xl-12 order-2 order-xl-1">
          <div className="row align-items-center">
            <div className='col-md-3 kt-margin-b-20-tablet-and-mobile' >
              <div className="kt-form__group kt-form__group--inline ">
                <div className="kt-form__label"> <label htmlFor="statusFilter"> Status:</label> </div>
                <div className="kt-form__control">
                  <select
                    id="statusFilter" 
                    className="form-control kt-selectpicker"  
                    onChange={({ target }) => { setCurrentStatus(target.value) }}
                  >
                    <option value='All'      > All     </option>
                    <option value='Active'   > Active   </option>
                    <option value='Expired'  > Expired </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-4 kt-margin-b-20-tablet-and-mobile'>
              <div className="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Users..."
                  id="generalSearch"
                  onChange={({target}) => setSearchTerm( target.value)}>
                </input>
                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i className="la la-search"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {allSubscribers.length > 0 ? (
        <div className="row">
          {selectedSubscriber && (
            <RightSideDetails
              onCloseDrawer = {closeDrawerHandler}
              show={!!selectedSubscriber}
              title="User Details">
              <div className="kt-portlet">
                <div className="kt-portlet__body">
                  <div className="kt-widget kt-widget--user-profile-3">
                    <div className="kt-widget__top">
                      {selectedSubscriber.AgentCompanyLogo ?
                        <div className="kt-widget__pic kt-widget__pic--brand">
                            <div className="kt-media kt-media--circle">
							              <img src={selectedSubscriber.AgentCompanyLogo} alt="image"/>
							          </div>
                        </div>
                      :<div className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest">
                          {selectedSubscriber.AgentCompanyName && selectedSubscriber.AgentCompanyName.slice(0,1)}
                        </div>
                      }
                      <div className="kt-widget__content">
                        <div className="row align-items-center">
                          <div className="col-5">
                            <div className="kt-widget__head">
                              <span className="kt-widget__username">
                                {selectedSubscriber.AgentCompanyName}
                              </span>
                            </div>
                            <div className="kt-widget__subhead padding-t0 padding-b0">
                                <span className="padding-r10">
                                  <i className="fa fa-user"></i>
                                  {selectedSubscriber.FirstName + ' ' + selectedSubscriber.LastName}
                                </span>
                            </div>
                            <div className="kt-widget__subhead padding-t0 padding-b0">
                              <span className="padding-r10">
                                <i className="flaticon2-new-email"></i>
                                {selectedSubscriber.Email}
                              </span>
                              {selectedSubscriber.Phone && 
                                <span className="padding-r10">
                                  <i className="flaticon2-phone"></i>
                                  {selectedSubscriber.Phone}
                                </span>
                              }
                            </div>
                          </div>
                          <div className="col-4">
                              <div className="kt-widget__subhead padding-t5 padding-b0">
                                <span className="padding-r10">
                                  <i className="fa fa-id-card"> </i> 
                                  {`Subscribtion : ${(selectedSubscriber.IsFreeTrial == 'Y')? `Free`: 'Paid'}`}
                                  {(selectedSubscriber.IsFreeTrial == 'Y' && selectedSubscriber.IsExtended == 'Y') && (
                                    <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge-pill-width pill margin-l10 text-white"> EXT </span>
                                  )}
                                </span>
                              </div>
                          </div>
                          <div className="col-3">
                            <span className={`badge block-badge text-white font-weight-normal btn-w13 badge-${selectedSubscriber.ExpiryDate && helper.getSubscriptionStatus(selectedSubscriber.ExpiryDate).badgeColor} mx-2`}> 
                              { selectedSubscriber.ExpiryDate && helper.getSubscriptionStatus(selectedSubscriber.ExpiryDate).dateString }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="kt-portlet kt-prtlet--mobile">
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                  <div className="kt-portlet__head-label">
                    <nav className="file-nav navbar navbar-top">
                      <span className="top-nav-active" >Update Subscription</span>
                    </nav>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <div className="kt-portlet__head-wrapper">
                      <div className="kt-portlet__head-actions">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__body padding-30">
                  <form className="kt-form kt-form--label-right" id="form-subscriptionUpdate" onSubmit={e => e.preventDefault()}>
                    <div className="kt-section__body">
                      <div className="form-group row">
                        <div className="col">
                          <CustomEditor 
                              placeholder="Comments"
                              prevComment = {selectedSubscriber.Comment}
                              setData={(d) => onEditHandler('Comment', d)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-2">
                          <label> Start Date </label>
                          <input
                            id="startDate"
                            name="startDate"
                            type ='date'
                            className = "form-control"
                            min={moment(selectedSubscriber.StartDate).format("YYYY-MM-DD")}
                            value={subscriberData.StartDate ? moment( subscriberData.StartDate).format('YYYY-MM-DD'): ''}
                            onChange ={({target}) => onEditHandler('StartDate', target.value)}
                          />
                        </div>
                        <div className="col-2">
                          <label> End Date </label>
                          <input
                            id="expDate"
                            name="expDate"
                            type ='date'
                            className = "form-control"
                            min={moment(selectedSubscriber.ExpiryDate).format("YYYY-MM-DD")}
                            value={subscriberData.ExpiryDate ? moment( subscriberData.ExpiryDate).format('YYYY-MM-DD'): ''}
                            onChange ={({target}) => onEditHandler('ExpiryDate', target.value)}
                          />
                        </div>
                        <div className="col-2 pl-5">
                          <label> Sub Agency </label>
                          <div className="d-flex align-item-center">
                            <span className="kt-switch kt-switch--icon">
                              <label>
                                <input
                                  type="checkbox" name=""
                                  checked = { subscriberData.SubAgency === "Y" ? "checked" : ""}
                                  onChange = {() => {
                                    subscriberData.SubAgency === "Y"
                                    ? onEditHandler("SubAgency", "N")
                                    : onEditHandler("SubAgency", "Y");
                                  }}
                                />
                                <span>{}</span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="col-2 pl-5">
                          <label> Team </label>
                          <div className="d-flex align-item-center">
                            <span className="kt-switch kt-switch--icon">
                              <label>
                                <input
                                  type="checkbox" name=""
                                  checked = { subscriberData.Team === "Y" ? "checked" : ""}
                                  onChange = {() => {
                                    subscriberData.Team === "Y"
                                    ? onEditHandler("Team", "N")
                                    : onEditHandler("Team", "Y");
                                  }}
                                />
                                <span>{}</span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="col-2 pl-5">
                          <label> Active </label>
                          <div className="d-flex align-item-center">
                            {/* <span className="pt-2 mr-3">Active</span> */}
                            <span className="kt-switch kt-switch--icon">
                              <label>
                                <input
                                  type="checkbox" name=""
                                  checked = { subscriberData.Active === "Y" ? "checked" : ""}
                                  onChange = {() => {
                                    subscriberData.Active === "Y"
                                    ? onEditHandler("Active", "N")
                                    : onEditHandler("Active", "Y");
                                  }}
                                />
                                <span>{}</span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div className="col-1 ">
                          <label> license </label>
                          <div className="d-flex align-item-center">
                              <input
                                name="license"
                                id="license"
                                value={subscriberData.License || ''}
                                max='100'
                                className="form-control"
                                type="number"
                                onChange ={({target}) => onEditHandler('License', target.value)}
                                />
                          </div>
                        </div>
                        <div className='col-1 text-right pl-2 pt-4'>
                          <button className="btn btn-brand btn-sm margin-t10" id='updateSubs-btn' onClick={onUpdateSubscription} >
                            Save
                          </button>
                        </div>
                        
                      </div>
                    </div>
                  </form>                    

                </div>
              </div>
            </RightSideDetails>
          )}
          <div className="col-sm-12">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
              <table className="kt-datatable__table">
                <thead
                  className="kt-datatable__head"
                  style={{ display: "block" }}>
                  <tr className="kt-datatable__row block-row">
                    <th className="kt-datatable__cell text-clip" width="25%">
                      <span>Company Name</span>
                    </th>
                    <th className="kt-datatable__cell text-clip" width="10%">
                      <span> Subscription </span>
                    </th>
                    <th className="kt-datatable__cell kt-datatable__cell--center text-clip" width="20%">
                      <span> Expiry Date </span>
                    </th>
                    <th className="kt-datatable__cell kt-datatable__cell--center  text-clip" width="15%">
                      <span> Status </span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable mb-1"
              onScroll={helper.setStickyHeader}>
              <table className="kt-datatable__table table-striped">
                <tbody className="kt-datatable__body" style={{ display: "block" }}>
                  {allSubscribers.map((u, i) => {
                    return (
                      <tr key={i} className="kt-datatable__row block-row clickable" onClick={() => {selectUserHandler(u)}}>
                        <td
                          className="kt-datatable__cell text-clip"
                          width="25%"
                          title={u.AgentCompanyName}>
                            <div className="kt-user-card-v2">
                              <div className="kt-user-card-v2__pic">
                                {u.AgentCompanyLogo ?
                                    <img src={u.AgentCompanyLogo} alt="image"/>
                                  :
                                  <div className="kt-badge kt-badge--xl kt-badge--info">
                                    {u.AgentCompanyName.slice(0, 1)}
                                  </div>
                                }
                              </div>
                              <div className="kt-user-card-v2__details text-clip">
                                <span className="kt-user-card-v2__name text-clip">
                                  {u.AgentCompanyName}
                                </span>
                                {u.Email && (
                                <span className="kt-user-card-v2__desc text-clip" >
                                {u.Email.toLowerCase()}
                                </span>
                              )}
                              </div>
                            </div>
                        </td>
                        <td className="kt-datatable__cell text-clip" width="10%">
                          <span > 
                            {(u.IsFreeTrial == 'Y')? `Free Trial`: 'Paid'}
                            {(u.IsFreeTrial == 'Y' && u.IsExtended == 'Y') && (<span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge-pill-width pill margin-l10"> EXT </span>)}
                          </span>
                        </td>

                        <td className="kt-datatable__cell--center kt-datatable__cell text-clip" width="20%"
                          title= {`${moment(u.ExpiryDate).format('DD/MM/YYYY')}`}>
                          <span > {`${moment(u.ExpiryDate).format('DD/MM/YYYY')}`}</span>
                        </td>

                        <td className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%" >
                          <span className={`block-badge badge text-white font-weight-normal  badge-${u.ExpiryDate && helper.getSubscriptionStatus(u.ExpiryDate).badgeColor} mx-2`}> 
                            { u.ExpiryDate && helper.getSubscriptionStatus(u.ExpiryDate).dateString }
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="padding-20" style={{ margin:'auto'}}>
            <div className="empty-image-container">
                <img style={{ width: '80%' }} src={noClient} alt="no-client-img" />
                <h4 className="margin-t20" style={{ fontSize :'18px' }}> No Users Found </h4>
            </div>
        </div>
      )}
    </div>
  );

    
}

export default SubscriptionTable;