import React, { useEffect } from 'react';
import '../../container/App.css';

import DashboardImg from '../../assets/images/dashboard.svg';

const Dashboard = props => {


    useEffect(() => {
        props.title('Dashboard', 'menuDashboard')
    }, [])

    return (
        <div style={{ padding: '20px' }}>
            <div className="empty-image-container" >
                <img src={DashboardImg} alt="Dashboard" />
                <h4>Dashboard coming soon...</h4>
            </div>
        </div>
    )

}

export default Dashboard;

