import { axiosGet, axiosPatch } from "./axiosFunctions";
import { checkLoginToken } from "./common";


const getTempSubscriptionUrl = "/api/subscription/getTempSubscription";
const updateTempSubscriptionUrl = "/api/subscription/updateTempSubscription";

export const getTempSubscription =  async () => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosGet( 
        getTempSubscriptionUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );    
    
    return response;
}

export const updateTempSubscription =  async (data) => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosPatch( 
        updateTempSubscriptionUrl,{data},
        { headers: { auth_token: `bearer ${token}` } }
    );    
    
    return response;
}