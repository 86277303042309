import {axiosGet, axiosPatch} from "./axiosFunctions";
import {checkLoginToken} from "./common";

const getServerTsUrl                    = "/api/agent/getServerTs";
const getAgentsAllUrl                   = "/api/agent/getAgentsAll";
const updateAgentAppUpdateUrl           = "/api/agent/updateAgentAppUpdate";
const updateAgentAppServiceUrl          = "/api/agent/updateAgentAppService";
const getAgentAppUpdateAndAppServiceUrl = "/api/agent/getAgentAppUpdateAndAppService";
const getPolicyCountUrl                 = "/api/agent/getPolicyCount";
const getAgentCompanyAllUrl             = "/api/agent/getAgentCompanyAll";


export const getServerTs = async () => {
    const response = await axiosGet(getServerTsUrl);
    return response;
}

export const getAgentAppUpdateAndAppService = async () => {
    const response = await axiosGet( getAgentAppUpdateAndAppServiceUrl );
    return response;  
};

export const getAgentsAll = async () => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosGet( 
        getAgentsAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );    
    
    return response;
}; 

export const getAgentCompanyAll = async () => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosGet( 
        getAgentCompanyAllUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );    
    
    return response;
};




export const updateAgentAppUpdate = async (data) => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosPatch( 
        updateAgentAppUpdateUrl,
        {data},
        { headers: { auth_token: `bearer ${token}` } }
    );
    
    return response;
};

export const updateAgentAppService = async (data) => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosPatch( 
        updateAgentAppServiceUrl,
        {data},
        { headers: { auth_token: `bearer ${token}` } }
    );
    
    return response;
};

export const getPolicyCount = async (companyId) => {
    const token = checkLoginToken();
    if (!token) return;

    const response  = await axiosGet( 
        `${getPolicyCountUrl}/${companyId}`,
        { headers: { auth_token: `bearer ${token}` } }
    )

    return response;
}


