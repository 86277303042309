import React, { useEffect, useState } from "react";
import { getAuthState, setAuthState } from "../../assets/scripts/login-util";
import "../../assets/styles/header-light.css";
import "./header.css";

const Header = (props) => {

  const [username, setUsername] = useState("Admin");

  // useEffect(() => {
  //   const user = getAuthState("user");
  //   setUsername(user.userName);
  // }, []);

  const logout = () => {
    setAuthState()
    window.location.reload();
  };

  let classes = "kt-header kt-grid__item  kt-header--fixed header-curtailed";

  return (
    <div id="kt_header" className={classes}>
      {/* begin:: Header Menu */}
      <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
        <div
          id="kt_header_menu"
          className='kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default' 
        >
          <h3 className="header-title">{props.title}</h3>
        </div>
      </div>
      <div className="kt-header__topbar">
          {/* begin: User Bar */}
          <div className="kt-header__topbar-item kt-header__topbar-item--user">
            <div
              className="kt-header__topbar-wrapper"
              data-toggle="dropdown"
              data-offset="0px,0px"
            >
              <div className="kt-header__topbar-user">
                <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Hi,
                </span>
                <span className="kt-header__topbar-username kt-hidden-mobile">
                  {username}
                </span>
                <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                 {username[0].toUpperCase()}
                </span>
              </div>
            </div>

            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
              <button
                onClick={logout}
                id="admin-signout-btn"
                className="btn btn-label btn-label-brand btn-block btn-bold"> 
                Sign Out
              </button>
            </div>
          </div>
          {/* end: User Bar */}
        </div>
      
    </div>
  );
};

export default Header;
