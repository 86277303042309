import React,{useState, useEffect}               from "react";
import { BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import Admin                                     from "./Admin";
import Login                                     from "../components/login/login";
import {getAuthState}                            from "../assets/scripts/login-util";
import "./App.css";


const App = () => {
  const [auth, setAuth] = useState(getAuthState('bool'));

  useEffect(()=>{
    const authorized = getAuthState('bool');
    if (!authorized ) return;
    
    setAuth(authorized);
  },[])

  return (
    
    <BrowserRouter>
      {auth ?
        <Switch>
          <Route path="/">
            <Admin />
          </Route>
        </Switch>
        :
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Redirect to="/login" />
        </Switch>
      }
    </BrowserRouter>
  );
};

export default App;
