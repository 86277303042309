import React,{ useState, useEffect } from "react";
import Editor from 'ckeditor5-custom-build';
import { CKEditor } from "@ckeditor/ckeditor5-react";

const CustomEditor = (props) => {
    const {prevComment , placeholder} = props;

    const [editorData, setEditorData]  = useState('');

    useEffect(() => {
        props.setData(editorData);
    }, [editorData]);

    useEffect(() => {
        setEditorData(prevComment);
    },[prevComment])

    const updateQuickTextHandler = (event, editor) => {  
        setEditorData(editor.getData());
    }

    return(<>
        <CKEditor 
            editor={ Editor }
            config={{
                toolbar: ["Bold", "Italic", "RemoveFormat", "|", "Undo", "Redo"],
                placeholder: placeholder,
            }}  
            data={editorData || ''}              
            onChange={updateQuickTextHandler}
        />
    </>);
}

export default CustomEditor;