import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"
import moment from "moment";
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import * as helper from "../global/helper"
import {loginAPI, loginRequestAPI} from "../api/loginAPI";
import {setAuthState} from "../../assets/scripts/login-util";
import bannerLogo from "../../assets/images/banner-logo.png";
import "../../assets/styles/login.css";

const Login = () => {

  const [loginData, setLoginData]             = useState({ isremember : true });
  const [isUser,    setIsUser]                = useState(false);
  const [timer, setTimer]                     = useState(20)
  const [otpTimer, setOtpTimer]               = useState(180)
  const [counter, setCounter ]                = useState({ m : 3, s : 0})
  const [intervalId, setIntervalId]           = useState({});
  const [serverTimestamp, setServerTimestamp] = useState(null);

  useEffect(()=>{
    let ignore = false;
    
    const updateCounter = () =>{
      let d = otpTimer % (60 * 60 );
      let ds = Math.ceil(d % 60);
      let t = {m : Math.floor(d/ 60), s: ds < 10 ?`0${ds}`:ds}
      setCounter(t)
    }
    
    if(!ignore) updateCounter();
    return () =>{
     ignore = true;
    }
  }, [otpTimer])


  useEffect(()=>{
    if(serverTimestamp){
      const secondsToExpire = moment().diff(serverTimestamp, 'seconds');
      showTimer(180 - secondsToExpire);
    }
  },[serverTimestamp]);


  const showTimer = (secondsToExpire) => {
    
    if(secondsToExpire < 0) {
      setOtpTimer(0);
      setTimer(0)
      return
    }
    
    //OTP RESEND TIMER
    setTimer(20)
    const resendInterval = setInterval(() => {
      setTimer(t =>{
        if(t > 0){
          return (t-1)
        }else{
          clearInterval(resendInterval)
          return 0
        }
      })
    }, 1000);
    
    //OTP EXP TIMER
    let otpInterval;
    if(secondsToExpire <= 180 && secondsToExpire > 0){
      setOtpTimer(secondsToExpire);
      otpInterval = setInterval(() => {
        setOtpTimer( t =>{
        if(t > 0){
          return (t-1)
        }else{
          clearInterval(otpInterval)
          return 0
        }
        })
      }, 1000);
    }
    //SET IntervalIds for Resetting
      setIntervalId({ resendInterval, otpInterval });
  }

  const resetTime = () => {
    clearInterval(intervalId.resendInterval);
    clearInterval(intervalId.otpInterval);
  }

  const loginDataHandler =(key, value)=>{
    setLoginData((d)=> {return {...d,[key] : value}});
  }

  const onSubmitHandler  =async(e)=>{
    e.preventDefault();

    const btn = $('#kt_login_signin_submit');
    const form = $('#login-form');

    form.validate({
      rules:{
        Email:{
          required: true,
        }
      }
    })

    if(!form.valid()){
      return;
    }

    helper.StartProcessing(btn);
    try {
      //Call login APi
      const params  = await loginRequestAPI(loginData.Email);
      if( params && params.serverTimestamp){
        setIsUser(true);
        setServerTimestamp(params.serverTimestamp);
        helper.StopProcessing(btn);
        form.validate().destroy();
      }
    } catch (err) {
      helper.StopProcessing(btn);
      Swal.fire({
        icon: "error",
        titleText: "Error!",
        text: err.message,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-brand",
      });
    }
  }

  const onLoginHandler = async(e) =>{
    e.preventDefault();

    const btn = $('#kt_login_signin_login');
    const form = $('#login-form');

    form.validate({
      rules:{
        Otp:{
          required: true,
        },
        Email:{
          required: true,
        },
        
      }
    })

    if(!form.valid()){
      return;
    }

    helper.StartProcessing(btn);
    try {
      //Call login APi
      const params  = await loginAPI(loginData);
      
      //Set Auth State
      setAuthState(params, loginData.isremember);
      window.location.reload()
    } catch (err) {
      helper.StopProcessing(btn);
      Swal.fire({
        icon: "error",
        titleText: "Error!",
        text: err.message,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-brand",
      });
    }
  }
   
  const resendHandler = async (e) => {
    try {
      let response = await loginRequestAPI(loginData.Email);
      if( response && response.serverTimestamp){
        //Reset and start the interval process
        resetTime();
        //clear otp input 
        setLoginData( d =>{ 
          delete d.OTP;
          return {...d}
       });

        //set ServerTimestamp data again
        setServerTimestamp( response.serverTimestamp );
      }
    } catch (err) {
      swal.fire({
        icon : 'error',
        titleText : err.message,
        animation : true,
        customClass : { popup: 'margin-20', },
      });
    }
  }

  return (
    <>
      <div
        className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3"
        id="kt_login"
      >
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          style={{ backgroundColor: "#f7f8fa" }}
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <img height="38" src={bannerLogo} alt="logo" />
              </div>
              <div className="kt-login__signin">
                <div className="kt-login__head">
                  <h3 className="kt-login__title">Admin Login</h3>
                  <h5 className="text-center margin-t10"> { serverTimestamp && ( (counter.m == 0 && counter.s == 0) ?' OTP Expired ':`${counter.m}:${counter.s}`) } </h5>
                </div>
                <form className="kt-form" id='login-form' onSubmit={(e) => e.preventDefault()} >
                  <div className="input-group">
                    <input
                      onChange={({ target })=> !isUser  && loginDataHandler('Email', target.value.trim())}
                      value={loginData.Email || ''}
                      className="form-control form-control-lg"
                      type="email"
                      disabled={isUser}
                      placeholder="Email"
                      name="Email"
                      autoComplete="off"
                    />
                  </div>
                  {isUser && 
                    <div className="input-group">
                      <input
                        onChange={({ target })=>loginDataHandler('OTP', target.value.trim())}
                        value={loginData.OTP || ''}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="OTP"
                        name="Otp"
                        />
                    </div>
                  }
                  <div className="margin-t20">	
                      <label className="kt-checkbox kt-checkbox--tick kt-checkbox--blue">
                        <input type="checkbox" 
                        name="rememberme"
                        defaultChecked={loginData.isremember ?"checked":""}
                        onChange={()=>loginDataHandler("isremember",!loginData.isremember)}
                         /> Remember Me 
                        <span></span>
                      </label>
                  </div>
                  {isUser ?
                    <>
                      <div className="kt-login__actions">
                        <button
                          id="kt_login_signin_login"
                          className="btn btn-theme btn-elevate btn-block kt-login__btn-primary"
                          type="button"
                          disabled={otpTimer == 0}
                          onClick={onLoginHandler}
                          >
                        Login
                        </button>
                      </div>
                      <div className="row margin-t20">
                        <div className="col text-center">
                          <span className="kt-login__account-msg">
                            Didn't get it ?
                          </span>
                          &nbsp;&nbsp;
                          {timer > 0 ? `Please wait for ${timer} seconds to Resend OTP ` 
                          :<span id="kt_login_otp_resend" className="kt-login__account-link clickable" onClick={resendHandler} > Resend OTP </span>
                          }
                        </div>
                      </div>
                    </> 
                  :<div className="kt-login__actions">
                    <button
                      type="button"
                      onClick={onSubmitHandler}
                      id="kt_login_signin_submit"
                      className="btn btn-theme btn-elevate btn-block kt-login__btn-primary"
                      >
                    Next
                    </button>
                  </div>               
                  }
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
