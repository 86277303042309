import {getAuthState, setAuthState} from "../../assets/scripts/login-util";
import swal from "sweetalert2";


export const checkLoginToken=()=>{
    const token = getAuthState('token');

    if(!token || token == 'Expired'){
        swal.fire({
            icon               : 'warning',
            titleText          : 'Session Expired',
            text               : 'Kindly Login',
            timer              : 2000,
            showConfirmButton  : false,
        });
        setAuthState();
        window.location.reload();
        return null;
    }
    return token;
}

