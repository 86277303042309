import { useState, useEffect } from "react";
import  * as helper from '../global/helper';
import nofile from "../../assets/images/no-file.svg";
import emailLogo from "../../assets/images/emailer.png";
import { getEmailStats } from "../api/emailerAPI";
import swal from "sweetalert2";
import moment from "moment";
import Loading from '../global/loading';

const EmailStatTable = (props) => {

    const [loading,             setLoading]              = useState();
    const [emailStats,          setEmailStats]           = useState([]);
    const [filteredEmailStats,  setfilteredEmailStats]   = useState([]);
    const [searchTerm,          setSearchTerm]           = useState('');

    useEffect(() => {
      let ignore = false;
      const fetchEmailStats = async() => {
        setLoading(true)
        try {
          const response = await getEmailStats();
          if(response) {
            setEmailStats(response); 
            setfilteredEmailStats(response)
            setLoading(false)
          }
        } catch (error) {
          setLoading(false)
          swal.fire({
            icon                : 'error',
            titleText           : 'Error!',
            text                : err.message,
            buttonsStyling      : false,
            confirmButtonClass  : 'btn btn-brand',
          });  
        }
      }    
      if(!ignore) fetchEmailStats();
        return () => {
          ignore = true;
        }
    },[])
    
    useEffect(() => {  
      let stats = emailStats;

      if(searchTerm == '') return setfilteredEmailStats(emailStats);
      
      const matchedData = stats.filter(c => {
        let matchString = ` ${c.Subject} ${moment(c.SendDate).format("DD/MM/YYYY hh:mm A")}`;
        let matchFound = 0;
          
        const searchArr = searchTerm.split(' ');
        searchArr.forEach(term => {
          matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0 ;
        });
          
        return matchFound === searchArr.length;
      })
    
      setfilteredEmailStats(matchedData);

    },[searchTerm])

    useEffect(()=>{
      helper.SetScrollHeight();
    });

    return(<>
      <div className="kt-form kt-form--label-right kt-margin-b-10">
        {loading ? <Loading />
        :<>
          <div className='row align-items-center stickyFilter'>
            <div className="col-xl-12 order-2 order-xl-1 px-0">
              <div className="row align-items-center">
                  <div className='col-md-4 kt-margin-b-20-tablet-and-mobile'>
                    <div className="kt-input-icon kt-input-icon--left ml-2">
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Search Email..."
                        id="generalSearch"
                        onChange={({target}) => setSearchTerm(target.value) } />
                        <span className="kt-input-icon__icon kt-input-icon__icon--left">
                            <span>
                                <i className="la la-search"></i>
                            </span>
                        </span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
       
        
        {filteredEmailStats.length > 0 ? (
          <div className="row">
            <div className="col-sm-12">
              <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                <table className="kt-datatable__table">
                  <thead
                    className="kt-datatable__head"
                    style={{ display: "block" }}>
                    <tr className="kt-datatable__row block-row">
                      <th className="kt-datatable__cell text-clip" width="50%">
                        <span> Subject </span>
                      </th>
                      <th className="kt-datatable__cell" width="15%">
                        <span>  Sent On </span>
                      </th>
                      <th className="kt-datatable__cell" width="15%">
                        <span>  Recipient </span>
                      </th>
                      <th className="kt-datatable__cell" width="10%">
                        <span> Opened </span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div
                className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                // onScroll={helper.setStickyHeader}
                >
                <table className="kt-datatable__table table-striped">
                  <tbody
                    className="kt-datatable__body"
                    style={{ display: "block", minHeight:'40vh' }}>
                    {filteredEmailStats.map((t, i) => {
                      return (
                        <tr key={i} className="kt-datatable__row block-row ">
                          <td className="kt-datatable__cell text-clip" width="50%" title={t.Subject}>
                            <div className="kt-user-card-v2">
                              <img src={emailLogo} alt="image" style={{height:'35px'}}/>
                              <div className="kt-user-card-v2__details text-clip ml-3">
                                <span className="kt-user-card-v2__name text-clip">
                                  {t.Subject}
                                </span>
                              </div>
                            </div>
                          </td>
                          
                          <td className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%"
                            title= {`${moment(t.SendDate).format('DD/MM/YYYY')} - ${moment(t.SendDate).format("hh:mm A")}`}>
                            <span > {`${moment(t.SendDate).format('DD/MM/YYYY')} - ${moment(t.SendDate).format("hh:mm A")}`}</span>
                          </td>

                          <td className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%"
                            title= {t.Recipient}>
                            <span >{t.Recipient} </span>
                          </td>

                          <td className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%"
                            title= {t.Read}>
                            <span >{t.Read} </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
            <div className="padding-20" style={{ margin:'auto'}}>
                <div className="empty-image-container">
                    <img style={{ width: '80%' }} src={nofile} alt="no-client-img" />
                    <h4 className="margin-t20" style={{ fontSize :'18px' }}> No Emails Found </h4>
                </div>
            </div>
        )}
        
        </>}
      </div>
    
    </>)
}

export default EmailStatTable