import moment from 'moment';
import { getServerTs } from '../../components/api/agentsAPI';
import swal from "sweetalert2";


let serverTs;

setTimeout(async () => { 
    try {
        serverTs  = await getServerTs();  
    } catch (error) {
        swal.fire({
            icon              : "error",
            title             : "Something went wrong..!",
            text              : 'Please Reload...',
            buttonsStyling    : false,
            confirmButtonClass: "btn btn-brand",
          });
    }
},100);


const currentMonthStart     = moment(serverTs).startOf('month').format('YYYY-MM-DD');
const currentMonthEnd       = moment(serverTs).endOf('month').format('YYYY-MM-DD');
const prevMonthStart        = moment(serverTs).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
const prevMonthEnd          = moment(serverTs).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
const prevThreeStart        = moment(serverTs).subtract(3, 'months').startOf('month').format('YYYY-MM-DD');


export const Periods = [
    {
        id: 1,
        name: 'Current Month',
        from: currentMonthStart,
        to: currentMonthEnd
    },
    {
        id: 2,
        name: 'Previous Month',
        from: prevMonthStart,
        to: prevMonthEnd
    },
    {
        id: 3,
        name: 'Previous 3 Months',
        from: prevThreeStart,
        to: prevMonthEnd
    },
    {
        id: 4,
        name: 'Custom',
        from: null,
        to: null
    }

]
