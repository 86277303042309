import React, { useState }        from "react";
import { Route, Switch,Redirect } from "react-router-dom";
import Header                     from "../components/global/header";
import SideMenu                   from "../components/global/sideMenu";
import Dashboard                  from "../components/dashboard/dashboard";
import UserStats                  from "../components/userStats/userStats";
import AppActions                 from '../components/appAction/appAction';     
import Emailer                    from "../components/emailer/emailer";    
import Subscription               from "../components/subscription/subscription";
import "./App.css";

const Admin = () => {
  const [headerTitle,     setHeaderTitle] = useState("Dashboard");
  const [menuId,               setMenuId] = useState("menuDashboard");

  const toggleState = (title, menuId) => {
    setHeaderTitle(title);
    setMenuId(menuId);
  };

  return (
    <div className="kt-grid kt-grid--hor kt-grid--root">
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <SideMenu menuID={menuId} />
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
          id="kt_wrapper">
          <Header title={headerTitle} />
          <div
            style={{ paddingTop: "0px" }}
            className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0"
            id="kt_content">
            <Switch>
                <Route path="/dashboard">
                    <Dashboard title = {toggleState} />
                </Route>
                <Route path="/user-stats">
                  <UserStats title = {toggleState} />
                </Route>
                <Route path="/appActions">
                   <AppActions title = {toggleState}/>
                </Route>
                <Route path="/emailer/:type">
                   <Emailer title = {toggleState}/>
                </Route>
                <Route path="/subscription">
                   <Subscription title = {toggleState}/>
                </Route>
                <Redirect to="/dashboard" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;