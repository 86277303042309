import React,{useState,useEffect}  from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { getTempSubscription } from "../api/subscriptionAPI";
import Loading  from "../global/loading";
import SubscriptionTable from "./subscription-table";
import "../userStats/userStats.css";


const Subscription = (props) => {

  const [AllSubscribers,  setAllSubscribers] = useState([]);
  const [loading,         setLoading]        = useState(false);
  const [refetch,     setRefetch]            = useState(false);
  

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      props.title("Subscription","menuSubscription");
      fetchSubscriptions();
    }
    return () => ignore = true;
  },[]);

  useEffect(() => {
    if(refetch){ 
      fetchSubscriptions();
      setRefetch(false);
    }
  },[refetch])

  const fetchSubscriptions= async() => {
    try {
      setLoading(true);
      const response = await getTempSubscription();
      if(response && response.length > 0){
        setAllSubscribers(response);
        setLoading(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        titleText: "Error!",
        text: error.message,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-brand",
      });
      setLoading(false);
    }
  };


    
  return (     
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
        <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
              <div className="kt-portlet__head-label">
                  <nav className="file-nav navbar navbar-top">
                  <NavLink activeClassName="top-nav-active" to="/subscription">
                    Subscription
                  </NavLink>
                  </nav>
              </div>
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <div className="kt-portlet__head-actions">
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-portlet__body kt-portlet__body--fit">
            {loading ? (
                <Loading />
            ) : (
              <SubscriptionTable 
                AllSubscribers = {AllSubscribers}
                refetchSubscription = {v => setRefetch(v)}
              />
            )}
            </div>
        </div>
    </div>
  )
}

export default Subscription;