import React from "react";

const Template = (props) =>{
    const {templatePreview} = props;

    return(<>
        <table style={{width: "100%" }} cellSpacing="0" cellPadding="0" border="0" bgcolor="#fff" align="center">
            <tbody>
                <tr>
                    <td>
                        <table cellSpacing="0" cellPadding="0" border="0" align="center" style={{width:'100%', borderTop: '1px solid #eee', borderRight: '1px solid #eee', borderLeft: '1px solid #eee', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}>
                            <tbody>
                                <tr>
                                    <td style={{width: "100%" }}>
                                        <table style={{width: "100%" }} cellSpacing="0" cellPadding="0" border="0" align="center">
                                            <tbody>
                                                <tr>
                                                    <td align="center" vertical-align="middle" style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}>
                                                        <table style={{width: '80%', fontFamily: 'Helvetica, arial, sans-serif', fontSize: '14px', borderCollapse: 'collapse', color: '#666666', textTransform: 'uppercase'}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td align="center" style={{padding: '15px'}}>
                                                                        <img src="https://insurebox.s3.ap-south-1.amazonaws.com/Public/IB_Logo.png" height="34px" alt="insurebox_logo"/> 
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>

        <table cellSpacing="0" cellPadding="0" border="0" bgcolor="#fff" align="center" style={{width: '100%', borderTop: '1px solid #eee', borderRight: '1px solid #eee', borderLeft: '1px solid #eee', padding: '50px 0px 50px'}} >
            <tbody>
                <tr>
                    <td>
                        <table cellSpacing="0" cellPadding="0" bgcolor="#f8fafb" border="0" align="center" style={{width: '100%', borderTop: '1px solid #eee', borderRight: '1px solid #eee', borderLeft: '1px solid #eee', padding: '50px 0px 50px'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table align="center" cellSpacing="0" cellPadding="0" bgcolor="#fff" style={{width: '80%', borderRight: '1px solid #eee', borderLeft: '1px solid #eee', borderBottom: '1px solid #eee', marginTop:'30px',  marginBottom:'30px' }}>
                                            <tbody>
                                                <tr>
                                                    <td align="center">
                                                        <table cellSpacing="0" cellPadding="0" border="0" align="center" style={{width: '80%', margin: '20px', fontFamily: 'Verdana, Geneva, sans-serif', color: '#666766', fontSize: '14px', borderCollapse: 'collapse'}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td align="left" valign="top" style={{paddingTop: '20px'}}>
                                                                        <p>
                                                                            <font style={{fontFamily: "Georgia, 'Times New Roman', Times, serif", color: '#666766', fontSize: '20px'}}>
                                                                                Hi User,
                                                                            </font>
                                                                        </p>
                                                                            {templatePreview && (
                                                                                <div dangerouslySetInnerHTML = {{ __html:templatePreview}} ></div>
                                                                            )}
                                                                        <p style={{marginTop:'35px'}}>
                                                                            <font style={{fontFamily: 'Verdana, Geneva, sans-serif', color: '#666766', fontSize: '14px', lineHeight: '28px'}}>
                                                                                Have a great day!<br/>
                                                                                Team InsureBox
                                                                            </font>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>

        <table align="center" cellSpacing="0" cellPadding="0" border="0" bgcolor="#fff" style={{width: "100%" }}>
            <tbody>
                <tr>
                    <td>
                        <table cellSpacing="0" cellPadding="0" border="0" align="center"
                            style={{width: '100%', borderBottom: '1px solid #eee', borderRight: '1px solid #eee', borderLeft: '1px solid #eee', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', borderTop: '1px solid #eee'}}>
                            <tbody>
                            <tr>
                                <td style={{width: "100%" }}>
                                <table style={{width: '100%', bordercollapse: 'collapse'}} cellSpacing="0" cellPadding="0" border="0" align="center">
                                    <tbody>
                                    <tr>
                                        <td style={{fontFamily: 'Helvetica, arial, sans-serif', fontSize: '14px', textAlign: 'center', lineHeight: '24px', padding: '10px'}}>
                                            <div style={{marginBottom:'10px'}}>
                                                <span>
                                                <img src="https://insurebox.s3.ap-south-1.amazonaws.com/Public/IB_Poweredby.png" alt="" style={{height: '14px'}} /></span>
                                            </div>
                                            <div>
                                                <span ><img src="https://insurebox.s3.ap-south-1.amazonaws.com/Public/facebook.png" alt=""  style={{height: '20px', marginLeft: '10px'}} /></span>
                                                <span ><img src="https://insurebox.s3.ap-south-1.amazonaws.com/Public/linkedin.png" alt="" style={{height: '20px', marginLeft: '10px'}} /></span>
                                                <span ><img src="https://insurebox.s3.ap-south-1.amazonaws.com/Public/twitter.png" alt="" style={{height: '20px', marginLeft: '10px'}} /></span>
                                                <span ><img src="https://insurebox.s3.ap-south-1.amazonaws.com/Public/instagram.png" alt="" style={{height: '20px', marginLeft: '10px'}} /></span>
                                                <span ><img src="https://insurebox.s3.ap-south-1.amazonaws.com/Public/youtube.png" alt="" style={{height: '20px', marginLeft: '10px'}} /></span>
                                            </div>
                                            <div>
                                                <font style={{fontFamily: 'Verdana, Geneva, sans-serif', color: '#666766', fontSize: '8px'}}>InsureBox, 1901B KB Park, Vikhroli (W), Mumbai - 400079, India</font>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
        </table>

    </>);
}


export default Template;