import React,{useState,useEffect}  from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { getAgentCompanyAll } from "../api/agentsAPI";
import Loading  from "../global/loading";
import UserStatsTable from "./userStatsTable";
import "./userStats.css";


const UserStats = (props) => {

  const [AllUsers,     setAllUsers]     = useState([]);
  const [loading,      setLoading]      = useState(false);
  const [userCount,    setUserCount]    = useState(0);
  const [CompanyCount, setCompanyCount] = useState(0);

  useEffect(() => {
      let ignore = false;
      const fetchAgentsAll = async () => {
        try {
          setLoading(true);
          const response = await getAgentCompanyAll();
          if(response && response.length > 0){
              setAllUsers(response);
              setCompanyCount(response.length);
              setLoading(false);
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            titleText: "Error!",
            text: error.message,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-brand",
          });
          setLoading(false);
        }
      };
      if (!ignore) fetchAgentsAll();
      return () => {
        ignore = true;
      };
  },[]);

  useEffect(() => {
    if(AllUsers.length > 0 ){
      let users = 0;
      for (const a of  AllUsers){
        if( a.Member.length > 0) users = users + a.Member.length;
      }
      setUserCount(users);
    }
  },[AllUsers])
      

  useEffect(()=>{
      props.title("User Stats","menuUser-Stats");
  },[])

  const updateCompanyCount = (value) => {
    setCompanyCount(value)
  }
  const updateUserCount = (value) => {
    setUserCount(value)
  }
    
  return (     
    <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
        <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
              <div className="kt-portlet__head-label">
                  <nav className="file-nav navbar navbar-top">
                  <NavLink activeClassName="top-nav-active" to="/user-stats">
                      User Stats 
                  </NavLink>
                  </nav>
              </div>
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <div className="kt-portlet__head-actions">
                  <div className = "kt-portlet__head_subhead" >
                    <span className=" mr-4 ">
                      <i className="fa fa-id-card"></i> Companies : <strong>{CompanyCount}</strong>
                    </span>
                    <span className="">
                      <i className="fa fa-users"></i> Users : <strong>{userCount}</strong>
                    </span>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-portlet__body kt-portlet__body--fit">
            {loading ? (
                <Loading />
            ) : (
              <UserStatsTable 
                AllUsers={AllUsers}
                updateCompanyCount = {updateCompanyCount}
                updateUserCount    = {updateUserCount}
              /> 
            )}
            </div>
        </div>
    </div>
  )
}

export default UserStats;