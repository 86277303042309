import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "./axiosFunctions";
import { checkLoginToken } from "./common";

const getEmailTemplatesUrl    = "/api/emailTemplate/getEmailTemplates";
const addEmailTemplatesUrl    = "/api/emailTemplate/addEmailTemplate";
const updateEmailTemplateUrl  = "/api/emailTemplate/updateEmailTemplate";
const deleteEmailTemplateUrl  = "/api/emailTemplate/deleteEmailTemplate";
const sendPromotionalMailUrl  = "/api/emailTemplate/sendPromotionalMail";
const getAdminEmailLogUrl     = "/api/emailTemplate/getAdminEmailLog";
const requestEmailSendOTPUrl  = "/api/admin/requestEmailSendOTP";

export const getEmailTemplates = async () => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosGet( 
        getEmailTemplatesUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );    
    
    return response;
};

export const getEmailStats = async () => {
    const token =  checkLoginToken();
    if(!token) return;
    const response =  await axiosGet(
        getAdminEmailLogUrl,
        { headers:{ auth_token: `bearer ${token}` } } 
    )
    
    return response;
}

export const addEmailTemplate = async (data) => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosPost( 
        addEmailTemplatesUrl,
        {data},
        { headers: { auth_token: `bearer ${token}` } }
    );
    
    return response;
};

export const updateEmailTemplate = async (data) => {
    const token = checkLoginToken();
    if (!token) return;
    const response = await axiosPatch( 
        updateEmailTemplateUrl,
        {data},
        { headers: { auth_token: `bearer ${token}` } }
    );
    
    return response;
};

export const deleteEmailTemplate = async (ID) => {
    const token = checkLoginToken();
    if (!token) return;

    const response = await axiosDelete( 
        `${deleteEmailTemplateUrl}/${ID}`,
        { headers: { auth_token: `bearer ${token}` } }
    );
    
    return response;
};

export const sendPromotionalMail = async (data) => {
    const token = checkLoginToken();
    if(!token) return;

    const response  = await axiosPost(
        sendPromotionalMailUrl,
        {data},
        { headers: {auth_token: `bearer ${token}`}}
    );

    return response;
}

export const requestEmailSendOTP = async (data) => {
    const token = checkLoginToken();
    if(!token) return;

    const response  = await axiosGet(
        requestEmailSendOTPUrl,
        { headers: {auth_token: `bearer ${token}`}}
    );

    return response;
}