import React,{ useState, useEffect } from 'react'
import * as helper from "../global/helper";
import $ from "jquery";
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/jquery-validation.init';
import { getAgentsAll } from '../api/agentsAPI';
import { sendPromotionalMail, requestEmailSendOTP } from '../api/emailerAPI';
import swal from 'sweetalert2';
import Loading from '../global/loading';
import { getAuthState } from '../../assets/scripts/login-util';


const User = getAuthState('user');

const OtpModal = (props) => {

    const  { onDismissModal, emailData } = props;

    const [OTP,             setOtp]             =  useState();
    const [timer,           setTimer]           = useState(20)
    const [otpTimer,        setOtpTimer]        = useState(180)
    const [counter,         setCounter ]        = useState({m:3, s: 0})
    const [intervalId,      setIntervalId]      = useState({});
    const [serverTimeStamp, setServerTimeStamp] = useState();

    useEffect(() => {
        $('#otp-modal').modal({ backdrop: 'static' });
        $('#sendEmail-modal').addClass('modal-blur'); 

        $('#otp-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');
          $('#sendEmail-modal').removeClass('modal-blur');  
          onDismissModal(); 
        });

        $('#otp-modal').modal('toggle');
    }, []);


    useEffect(() => {
        let ignore = false;
       
        const requestOtp = async() => {
            const response =  await requestEmailSendOTP();
            if(response) setServerTimeStamp(response.serverTimestamp);
        }
        if(!ignore) requestOtp();
        return(() => {
            ignore = true;
        })
    },[])


    useEffect(() => {
        if(serverTimeStamp){
            const OTPExpirationSeconds = moment().diff(serverTimeStamp, 'seconds')
            showTimer(180 - OTPExpirationSeconds);
        }
    }, [serverTimeStamp])


    useEffect(()=>{
        let ignore = false;

        const updateCounter = () =>{   
            let d = otpTimer % (60 * 60 );
            let ds = Math.ceil(d % 60);
            let t = {m : Math.floor(d/ 60), s: ds < 10 ?`0${ds}`:ds}
            setCounter(t)
        }

        if(!ignore) updateCounter();
        return () =>{
            ignore = true;
        }
    }, [otpTimer])

    const showTimer = (secondsToExpire) => {
        if(secondsToExpire < 0) {
            setOtpTimer(0);
            setTimer(0)
            return
        } 
        //OTP RESEND TIMER
        setTimer(20)
        const resendInterval = setInterval(() => {
            setTimer(t =>{
                if(t > 0){
                    return (t-1)
                }else{
                    clearInterval(resendInterval)
                    return 0
                }
            })
        }, 1000);
        
        //OTP EXP TIMER
        let otpInterval;
        if(secondsToExpire <= 180 && secondsToExpire > 0){
            setOtpTimer(secondsToExpire);
            otpInterval = setInterval(() => {
                setOtpTimer(t =>{
                    if(t > 0){
                        return (t-1)
                    }else{
                        clearInterval(otpInterval)
                        return 0
                    }
                })
            }, 1000);
        }
        //SET IntervalIds for Resetting
        setIntervalId({ resendInterval, otpInterval });        
    }

    const resetTime = () => {
        clearInterval(intervalId.resendInterval);
        clearInterval(intervalId.otpInterval);
    }

    const resendHandler = async () => {
        try {
            let response = await requestEmailSendOTP();
            if(response){
                //Clear Inputs
                setOtp('');
                resetTime();
                setServerTimeStamp(response.serverTimestamp);
            }
        } catch (err) {
            swal.fire({
                icon        : 'error',
                titleText   : err.message,
                animation   : true,
                customClass : { popup: 'margin-20', },
            });
        }
    }

    const sendEmail = async (e) => {
        e.preventDefault();

        const form = $("#otp-form");
        form.validate({
            rules: {
                OTP: { required: true },
            },
        });

        if (!form.valid())  return;
    
        helper.StartProcessing($("#save-btn"));

        try {
            emailData.enteredOTP = OTP;
            await sendPromotionalMail(emailData);

            helper.StopProcessing($("#save-btn"));
            resetTime();
            $("#otp-modal").modal("hide");
            $("#sendEmail-modal").modal("hide");
            swal.fire({
                icon                : 'success',
                titleText           : 'Email sent successfully',
                showConfirmButton   : false,
                timer               : 1500,
                toast               : true,
                position            :'bottom-end'
            });
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            }); 
            helper.StopProcessing($("#save-btn"));    
        } 
    }

    return (
        <div className="modal fade" id="otp-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">  Validate OTP </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"> </button>
                    </div>
                    <div className="modal-body">
                        <div className="client-container">
                            <div className="row margin-b20">
                                <div className="col text-center">
                                    <div className="kt-login__desc"> 
                                        Verify OTP sent to <b>{User.email}</b>.
                                        <br/>
                                        <small>( Check your spam or update folder in case you haven't received OTP)</small>
                                    </div>
                                    <h5 className="text-center margin-t10"> {(counter.m == 0 && counter.s == 0)?' OTP Expired ':`${counter.m}:${counter.s}`} </h5>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <form id="otp-form" onSubmit={(e) => {  e.preventDefault()}}>
                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    id="OTP"
                                                    value={OTP || ''}
                                                    name="OTP"
                                                    placeholder="Enter OTP"
                                                    className="form-control margin-b10"
                                                    type="number"
                                                    minLength='6'
                                                    maxLength='6'
                                                    onChange={({ target: { value } }) => setOtp(value)}
                                                />          
                                            </div>
                                        </div>
                                    </form>  
                                </div>
                            </div>
                            <div className="row margin-t20">
                                <div className="col text-center">                                                
                                    <span className="kt-login__account-msg">
                                        Didn't get it ?
                                    </span>
                                    &nbsp;&nbsp;
                                    {timer > 0 ? `Please wait for ${timer} seconds to Resend OTP `
                                        :<span id="kt_login_otp_resend" className="kt-login__account-link clickable" onClick={resendHandler} > Resend OTP </span>
                                    }
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal" 
                            onClick={resetTime}
                        >   
                            Close
                        </button>
                        <button
                            id="save-btn"
                            type="button"
                            className="btn btn-brand margin-0"
                            onClick={sendEmail}
                        >   
                            Send Email
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


const SendEmailModal = (props) => {
    const  { selectedEmailTemplate } = props
    const [emailData,       setEmailData]       = useState({ targetAudience:'AllUsers', senderEmail: 'noreply@insurebox.co' });
    const [agents,          setAgents]          = useState([]);
    const [selectedAgents,  setSelectedAgents]  = useState([]);
    const [loading,         setLoading]         = useState(false);
    const [searchTerm,      setSearchTerm]      = useState('');
    const [currentStatus,   setCurrentStatus]   = useState('All');
    const [filteredAgents,  setFilteredAgents]  = useState([]);
    const [showOtpModal,    setshowOtpModal]    = useState(false);


    useEffect(() => {
        $('#sendEmail-modal').modal({ backdrop: 'static' });
  
        $('#sendEmail-modal').on('hidden.bs.modal', function () {
          $('.modal').hasClass('show') && $('body').addClass('modal-open');   
          props.onDismissModal(); 
        });
    }, []);

    
    useEffect(() => {
        let ignore = false;
        const fetchAgentsAll = async () => {
            try {
                setLoading(true);
                const response = await getAgentsAll();
               
                if(response && response.length > 0){
                    setAgents(response);
                    setFilteredAgents(response);
                    setLoading(false);
                }
            } catch (error) {
                swal.fire({
                    icon: "error",
                    titleText: "Error!",
                    text: error.message,
                    buttonsStyling: false,
                    confirmButtonClass: "btn btn-brand",
                });
                setLoading(false);
            }
        };
        if (!ignore ) {
            if(emailData.targetAudience === 'Custom'){
                fetchAgentsAll();
            }else{
                setCurrentStatus('All')
                setSelectedAgents([])
            }
        }
        return () => {
          ignore = true;
        };
    }, [emailData.targetAudience]);

    useEffect(() => {
        filterUsers();
    }, [currentStatus]);

    useEffect(() => {
        if(searchTerm == ''){
            setCurrentStatus('All')
            return setFilteredAgents(agents);
        }
        filterUsers();
    }, [searchTerm]);

    const filterUsers = () => {
        let allUsers = agents;
        
        if(currentStatus && currentStatus === 'All'){
            setFilteredAgents(allUsers)
        }

        if(currentStatus && currentStatus != 'All') {
            allUsers = allUsers.filter( a => helper.getFormatedDateWithStatus(a.LastActive).status === currentStatus);
            setFilteredAgents(allUsers)
        }

        if(searchTerm != ''){
          const matchedData = allUsers.filter(c => {
            let matchString = ` ${c.FirstName} ${c.LastName} ${c.AgentCompanyName} ${c.Email}`;
            let matchFound = 0;
      
            const searchArr = searchTerm.split(' ');
            searchArr.forEach(term => {
              matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0 ;
            });
      
            return matchFound === searchArr.length;
          })
          setFilteredAgents(matchedData);
        }
  
    }

    const handleInput = (key, value) => {
        setEmailData( d  => { return {...d, [key]: value}})
    }

    const handleAgentCheckbox = (a) => {
        if(selectedAgents.findIndex(u => u.ID === a.ID) === -1){
            setSelectedAgents( d => [...d, { ID : a.ID, Email : a.Email, FirstName: a.FirstName, LastName: a.LastName }])
        }else{
            const newAgents = selectedAgents.filter( u => u.ID !== a.ID);
            setSelectedAgents(newAgents)
        }
    }


    const sendEmailHandler = async(e) => {
        e.preventDefault();

        const form = $("#email-form");
        form.validate({
            rules: {
                subject: { required: true },
            },
        });

        if (!form.valid())  return;

        if (emailData.targetAudience === 'Custom' && !selectedAgents.length > 0) {
            return swal.fire({
              icon  : "error",
              title : "Please select the agents !",
            });
        }
        
        // helper.StartProcessing($("#save-btn"));
        try {
            //will accept template ID, subject, target audience, users
            setEmailData( d => {
                return { ...d, users : selectedAgents, templateID : selectedEmailTemplate.ID}
            })

            if(Object.keys(emailData).length > 0){
                setshowOtpModal(true);
            }
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            }); 
            helper.StopProcessing($("#save-btn"));    
        }
    }

    return(<>
        <div className="modal fade" id="sendEmail-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">  Send {selectedEmailTemplate ? selectedEmailTemplate.Name : 'Template'}   </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"> </button>
                </div>
                <div className="modal-body">
                    <div className="client-container">
                        <div className='row'>
                            <div className='col-12'>
                            <form id="email-form" onSubmit={(e) => {  e.preventDefault()}}>
                                <div className="row">
                                    <div className="col-12">
                                        <input
                                            id="subject"
                                            value={emailData.subject || ''}
                                            name="subject"
                                            placeholder="subject"
                                            className="form-control margin-b10"
                                            type="text"
                                            onChange={({ target: { value } }) => handleInput('subject', helper.capitalize(value)) }
                                        />          
                                    </div>
                                </div>
                                <div className='row'>       
                                    <div className="col-4">
                                        <input
                                            id="senderName"
                                            value={emailData.senderName || ''}
                                            name="senderName"
                                            placeholder="from"
                                            className="form-control margin-b10"
                                            type="text"
                                            onChange={({ target: { value } }) => handleInput('senderName', helper.capitalize(value)) }
                                        />          
                                    </div> 
                                    <div className="col-4">
                                        <select 
                                            id = 'selectSenderEmail'
                                            className = "form-control margin-b10"
                                            value = {emailData.senderEmail}
                                            onChange={({ target }) => { handleInput('senderEmail', target.value) }}
                                        >
                                            <option className='noreply@insurebox.co' > noreply@insurebox.co </option>
                                            <option className='help@insurebox.co'    > help@insurebox.co    </option>
                                            <option className='team@insurebox.co'    > team@insurebox.co   </option>
                                            <option className='support@insurebox.co' > support@insurebox.co </option>
                                            <option className='info@insurebox.co'    > info@insurebox.co </option>
                                        </select>    
                                    </div>
                                    <div className="col-4">
                                        <select 
                                            id = 'selectSenderEmail'
                                            className = "form-control margin-b10"
                                            value = {emailData.targetAudience}
                                            onChange={({ target }) => { handleInput('targetAudience', target.value) }}
                                        >
                                            <option className='AllUsers'>   All Users</option>
                                            <option className='Active'>     Active </option>
                                            <option className='Inactive'>   Inactive </option>
                                            <option className='Custom'>     Custom </option>
                                        </select>
                                    </div>
                                </div>
                              
                            </form>  
                            </div>
                        </div>
                        {emailData.targetAudience === 'Custom' && agents.length > 0  &&( <>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className="kt-portlet kt-portlet--mobile ">
                                        <div className="kt-portlet__head kt-portlet__head--lg padding-l0 " style={{minHeight : '50px'}}>
                                            <div className="kt-portlet__head-label">
                                                <nav className="file-nav navbar navbar-top">
                                                    <span className='top-nav-active padding-b11 margin-t5' >
                                                        Users
                                                    </span>
                                                </nav>
                                            </div>
                                            <div className="kt-portlet__head-toolbar">
                                                <div className="kt-portlet__head-wrapper">
                                                    <div className="kt-portlet__head-actions">
                                                        <div className = "kt-portlet__head_subhead" >
                                                            <span className="">
                                                                <i className="fa fa-users"></i> Selected Users : <strong> {selectedAgents.length || 0}</strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__body kt-portlet__body--fit">

                                            {loading ? <Loading/> 
                                            :(  <div>  
                                                    <div className='row align-items-center stickyFilter'>
                                                        <div className="col-xl-12 order-2 order-xl-1 px-0">
                                                            <div className="row align-items-center">
                                                                <div className='col-md-3 kt-margin-b-20-tablet-and-mobile'>
                                                                        
                                                                    <div className="kt-form__control">
                                                                        <select
                                                                            id="statusFilter" 
                                                                            value={currentStatus}  
                                                                            className="form-control kt-selectpicker"  
                                                                            onChange={({ target }) => { setCurrentStatus(target.value) }}
                                                                        >
                                                                            <option value='All'      > All     </option>
                                                                            <option value='Active'   > Active   </option>
                                                                            <option value='Inactive' > Inactive </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4 kt-margin-b-20-tablet-and-mobile'>
                                                                    <div className="kt-input-icon kt-input-icon--left">
                                                                        <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search Users..."
                                                                        id="generalSearch"
                                                                        onChange={({target}) => setSearchTerm( target.value)}>
                                                                        </input>
                                                                        <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                                                            <span>
                                                                                <i className="la la-search"></i>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                            
                                                    <div 
                                                        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable margin-b0"
                                                        onScroll={helper.setStickyHeader}>
                                                        <table className="kt-datatable__table table-striped">
                                                            <tbody className="kt-datatable__body" style= {{ display: 'block' }}>
                                                                {filteredAgents.map( (a, i) =>{
                                                                    return (
                                                                        <tr className="kt-datatable__row block-row" key={i} >
                                                                            <td width="70%" title='' className="kt-datatable__cell text-clip">
                                                                                <div className="kt-user-card-v2 text-clip">
                                                                                    <div className="kt-user-card-v2__pic">
                                                                                        <div className={`kt-badge kt-badge--xl kt-badge--${a.CategoryID == 1 ? 'success' : 'info' }`}>                                                                                 
                                                                                            {a.FirstName.slice(0,1)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="kt-user-card-v2__details text-clip">
                                                                                        <span className="kt-user-card-v2__name text-clip"> 
                                                                                            {`${a.FirstName} ${a.MiddleName} ${a.LastName}`}
                                                                                        </span>
                                                                                        <span className="kt-user-card-v2__desc text-clip"> 
                                                                                            {a.Email.toLowerCase()}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className ="kt-datatable__cell text-clip text-center" width="15%">
                                                                                <label className ="kt-checkbox kt-checkbox--brand">
                                                                                    <input type="checkbox"
                                                                                        onClick={() => handleAgentCheckbox(a)}
                                                                                    />&nbsp;<span></span>
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>   
                                                </div>    
                                            )}   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)} 
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary margin-0 margin-r5"
                        data-dismiss="modal">   
                        Close
                    </button>
                    <button
                        id="save-btn"
                        type="button"
                        className="btn btn-brand margin-0"
                        onClick={sendEmailHandler}
                    >   
                         Send
                    </button>
                </div>
            </div>
            </div>
        </div>

        {showOtpModal && (
            <OtpModal
                onDismissModal = {() => setshowOtpModal(false)}
                emailData = {emailData}
            />
        )}                                                            

    </>)
}


export default SendEmailModal;