import moment from "moment";
import React,{useState,useEffect} from "react";
import * as helper from "../global/helper";

const UserTable = (props) => {

    const [users, setUsers] = useState(props.users);

    useEffect(() => {
      helper.SetScrollHeight();
    });

    return (
        <div className="kt-form kt-form--label-right kt-margin-b-10">
            <div className="row align-items-center mt-0">
                <div className="col-xl-12">
                    {users.length > 0 ? (
                        <div className="row">
                        <div className="col-sm-12 padding-0">
                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                            <table className="kt-datatable__table">
                                <thead
                                className="kt-datatable__head"
                                style={{ display: "block" }}>
                                <tr className="kt-datatable__row block-row">
                                    <th className="kt-datatable__cell text-clip" width="25%">
                                        <span className="padding-l20">Name</span> 
                                    </th>
                                    <th className="kt-datatable__cell text-clip" width="12%">
                                        <span>Contact No</span>
                                    </th>
                                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="20%">
                                        <span>Registered On</span>
                                    </th>
                                    <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="15%">
                                        <span>Last Active</span>
                                    </th>
                                    <th className="kt-datatable__cell text-clip" width="12%">
                                        <span>Date Of Birth</span> 
                                    </th>
                                    <th className="kt-datatable__cell text-clip" width="15%">
                                        <span>Email Verified</span> 
                                    </th>
                                </tr>
                                </thead>
                            </table>
                            </div>
                            <div
                            className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                            onScroll={helper.setStickyHeader}>
                            <table className="kt-datatable__table table-striped">
                                <tbody
                                className="kt-datatable__body"
                                style={{ display: "block" }}>
                                {users.map((u, i) => {
                                    return (
                                        <tr
                                            key={i}
                                            className="kt-datatable__row block-row clickable">
                                            <td
                                                className="kt-datatable__cell text-clip"
                                                width="25%">
                                                <div className="kt-user-card-v2">                            
                                                    <div className="kt-user-card-v2__pic">
                                                        { u.ProfilePhoto ? 
                                                            <img src={u.ProfilePhoto} alt="image" />
                                                            :<div className="kt-widget__pic kt-widget__pic--brand kt-font-boldest">
                                                                <div className="kt-badge kt-badge--xl kt-badge--info">
                                                                    {u.FirstName.slice(0, 1)}
                                                                </div>
                                                            </div>
                                                        } 
                                                    </div>
                                                    <div className="kt-user-card-v2__details text-clip">
                                                        <span className="kt-user-card-v2__name text-clip">
                                                            <span> {`${u.FirstName} ${u.MiddleName} ${u.LastName}`}</span>
                                                            {u.CategoryID == 1 && (<span className="kt-badge kt-badge--success  kt-badge--inline kt-badge--pill kt-badge-pill-width pill margin-l10">Admin</span>)}
                                                        </span>
                                                        {u.Email && (
                                                            <span className="kt-user-card-v2__desc text-clip"  title={u.Email}>
                                                                {u.Email.toLowerCase()}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                className="kt-datatable__cell text-clip"
                                                width="12%"
                                                title={u.Mobile}>
                                                <div className="kt-user-card-v2">
                                                    <div className="kt-user-card-v2__details text-clip">
                                                        <span className="kt-user-card-v2__name text-clip">
                                                            {u.Mobile}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                className="kt-datatable__cell--center kt-datatable__cell text-clip"
                                                width="20%"
                                                title= {`${moment(u.CreatedDate).format('DD/MM/YYYY')} - ${moment(u.CreatedDate).format("hh:mm A")}`}>
                                                <span> 
                                                    {`${moment(u.CreatedDate).format('DD/MM/YYYY')} - ${moment(u.CreatedDate).format("hh:mm A")}`}
                                                </span>
                                            </td>
                                            <td
                                                className="kt-datatable__cell--center kt-datatable__cell text-clip"
                                                width="15%"
                                            >
                                                {u.LastActive ?(
                                                    <span className={`block-badge badge  font-weight-normal mx-2 badge-${u.LastActive && helper.getFormatedDateWithStatus(u.LastActive).badgeColor} `}> 
                                                        { u.LastActive && helper.getFormatedDateWithStatus(u.LastActive).dateString}
                                                    </span>
                                                ):(
                                                    <span className={`block-badge badge  font-weight-normal mx-2 badge-${helper.getFormatedDateWithStatus(u.UpdatedDate).badgeColor} `}> 
                                                        { helper.getFormatedDateWithStatus(u.UpdatedDate).dateString}
                                                    </span>
                                                )}
                                            </td>
                                            <td
                                                className="kt-datatable__cell text-clip"
                                                width="12%"
                                                title={u.Dob}>
                                                <div className="kt-user-card-v2">
                                                    <div className="kt-user-card-v2__details text-clip">
                                                        <span className="kt-user-card-v2__name text-clip">
                                                            {u.Dob && (`${moment(u.Dob).format('DD/MM/YYYY')}`) || ''}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                className="kt-datatable__cell text-clip padding-l30"
                                                width="15%"
                                                title={u.EmailVerified}>
                                                <div className="kt-user-card-v2">
                                                    <div className="kt-user-card-v2__details text-clip">
                                                        {u.EmailVerified === 'Y' ? 
                                                            <span className="kt-badge kt-badge--success  kt-badge--inline kt-badge--pill kt-badge-pill-width" style={{width: 45,lineHeight: 1.9}}>Yes</span>
                                                            :<span className="kt-badge kt-badge--warning  kt-badge--inline kt-badge--pill kt-badge-pill-width" style={{width: 45,lineHeight: 1.9}}>No</span>
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                    ) : (
                        <span classNameName="empty-table-message">No Users Found</span>
                    )}
                </div>
            </div>
        </div>
    );

    
}

export default UserTable;