import {axiosGet, axiosPost}  from "./axiosFunctions";
import {checkLoginToken} from "./common";

const loginRequestUrl = "/api/admin/loginRequest"
const resendLoginOTPUrl = "/api/admin/resendLoginOTP"
const loginUrl = "/api/admin/login"


export const loginRequestAPI = async (email) =>{
        const response = await axiosPost(loginRequestUrl, { email });   
        return response;
}


export const loginAPI = async (param) =>{
        const response = await axiosPost(loginUrl, param);   
        return response;
}