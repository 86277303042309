import React, { useState, useEffect } from "react";
import * as helper                    from "../global/helper";
import moment                         from "moment";
import RightSideDetails from "../global/rightSideDetails";
import { Periods } from '../../assets/scripts/periodSelection';
import UserTable from "./userTable";
import { getPolicyCount } from "../api/agentsAPI";
import swal from "sweetalert2";
import noClient from "../../assets/images/no-client.svg";


const UserStatsTable = (props) => {
  
    const {updateCompanyCount, updateUserCount} = props;
    const [AllUsers,       setAllUsers]         = useState(props.AllUsers);
    const [selectedUser,   setSelectedUser]     = useState(null);
    const [currentStatus,  setCurrentStatus]    = useState('All');
    const [currentPeriod,  setCurrentPeriod]    = useState({ id : 0, name : 'All', from : null, to : null });
    const [fromDate,       setFromDate]         = useState(null);
    const [toDate,         setToDate]           = useState(null);
    const [searchTerm,     setSearchTerm]       = useState('');
    const [activeCompanies, setActiveCompanies] = useState(0);
    const [activeUsers, setActiveUsers]         = useState(0);

    useEffect(()=>{
      helper.SetScrollHeight();
    });

    useEffect(() => {
      if(AllUsers.length > 0 ){
        updateCompanyCount(AllUsers.length)
        let users = 0;
        let activeUser = 0;
        for (const a of  AllUsers){
          if( a.Member.length > 0){
            users = users + a.Member.length;
            activeUser =  activeUser + a.Member.filter(m => helper.getFormatedDateWithStatus(m.LastActive).status == 'Active').length;
          }
        }
        let companiesCount = AllUsers.filter(a => helper.getFormatedDateWithStatus(a.CompanyLastActive).status == 'Active').length;
        setActiveCompanies(companiesCount)
        setActiveUsers(activeUser)
        updateUserCount(users);
      }else{
        updateCompanyCount(0)
        updateUserCount(0);
      }
    },[AllUsers])

    useEffect(()=>{
      if (currentPeriod && currentPeriod.id != 4 ){
        setFromDate(currentPeriod.from);
        setToDate(currentPeriod.to);
      }
    },[currentPeriod])


    useEffect(() => {
      if (currentPeriod && currentPeriod.id != 4)  filterUsers();
    }, [fromDate, toDate]);

    useEffect(() => {
      filterUsers();
    }, [currentStatus]);

    useEffect(() => {
      if(searchTerm == ''){
          setCurrentStatus('All')
          setCurrentPeriod({ id : 0, name : 'All', from : null, to : null })
          return setAllUsers(props.AllUsers);
      }

      filterUsers();
    }, [searchTerm]);


    const filterUsers = () => {
      let allUsers  = props.AllUsers;

      if(currentStatus && currentStatus != 'All') allUsers = allUsers.filter( a => helper.getFormatedDateWithStatus(a.CompanyLastActive).status === currentStatus);
     
      if(currentPeriod.id == 0){
        //for All
        setAllUsers(allUsers)
      }else if(currentPeriod.id == 4){
        //for custom
        allUsers = allUsers.filter(a =>  moment(a.CreatedDate).isBetween(fromDate, toDate, '', '[]')  );
        setAllUsers(allUsers);
      }else{
        allUsers = allUsers.filter(a =>  moment(a.CreatedDate).isBetween(fromDate, toDate, '', '[]')  );
        setAllUsers(allUsers);
      }
      

      if(searchTerm != ''){
        const matchedData = allUsers.filter(c => {
          let matchString = `${c.AgentCompanyName} ${c.Email} ${c.StateName} ${c.CityName} ${c.EmployeeStrength} ${c.ReferralCode} ${c.Phone} ${moment(c.CreatedDate).format("DD/MM/YYYY hh:mm A")}`;
          let matchFound = 0;
    
          const searchArr = searchTerm.split(' ');
          searchArr.forEach(term => {
            matchFound += matchString.toLowerCase().includes(term.toLowerCase()) ? 1 : 0 ;
          });
    
          return matchFound === searchArr.length;
        })
        setAllUsers(matchedData);
      }

    }

    const changePeriodHandler = ({ target }) => {
      if(target.value == 0){
        return setCurrentPeriod({ id : 0, name : 'All', from : null, to : null });
      }
      const period = Periods.find(p => p.id == target.value)
      setCurrentPeriod(period);
    }

    const selectUserHandler = async (u) => {
      try {
        if(u.AgentCompanyID){
          const response =  await getPolicyCount(u.AgentCompanyID);
          setSelectedUser({...u, PolicyCount: response || 0});
        }
      } catch (error) {
        swal.fire({
          icon: "error",
          titleText: "Error!",
          text: error.message,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-brand",
        });
      }
    } 

    const closeDrawerHandler = () => {
      setTimeout(() => {
        setSelectedUser(null);
      }, 180);
    };
    
    return (
    <div className="kt-form kt-form--label-right kt-margin-b-10">
      <div className="row align-items-center stickyFilter">
        <div className="col-xl-12 order-2 order-xl-1">
          <div className="row align-items-center">
            <div className={`col-md-${currentPeriod.id == 4 ? '2' : '3'} kt-margin-b-20-tablet-and-mobile`} >
              <div className="kt-form__group ">
                <div className="kt-form__control">
                  <select
                    id="userPeriod" 
                    value={currentPeriod.id}  
                    className="form-control kt-selectpicker"  
                    onChange={changePeriodHandler}
                  >
                    <option value={0} >
                      All       
                    </option>
                    {Periods.map((s) => (
                        <option value={s.id} key={s.id}>
                            {s.name}
                        </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {currentPeriod.id == 4 && (
                <div className="col-md-5 kt-margin-b-20-tablet-and-mobile">
                    <div className="row align-items-center">
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-5 margin-sm-b20 padding-lr0">
                                  <div className="kt-form__group form-group margin-b0">
                                      <div className="kt-form__control kt-form__group--inline">
                                        <input
                                          id="fromDate"
                                          value={fromDate || ''}
                                          max={moment().format('YYYY-MM-DD')}
                                          name="fromDate"
                                          className="form-control"
                                          type="date"
                                          onChange={(event) => setFromDate(event.target.value)}
                                        />
                                      </div>
                                  </div>
                                </div>
                                <div className="col-md-2 text-center d-none d-md-block padding-lr0">
                                  <div className="kt-form__group margin-t5">
                                    <div className="kt-form__control kt-form__group--inline">
                                      <label>To</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-5 margin-sm-b20 padding-lr0">
                                    <div className="kt-form__group form-group margin-b0">
                                      <div className="kt-form__control kt-form__group--inline">
                                        <input
                                          id="toDate"
                                          value={toDate || ''}
                                          max={moment().format('YYYY-MM-DD')}
                                          name="toDate"
                                          className="form-control"
                                          type="date"
                                          onChange={(event) => setToDate(event.target.value)}
                                        />
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 text-center">
                            <button className="btn btn-brand btn-block padding-8 margin-0" onClick={() => {filterUsers()}}>
                                <i className="la la-search"></i>
                            </button>
                        </div>
                    </div>                                   
                </div>
            )}

            <div className={`col-md-${currentPeriod.id == 4 ? '3' : '3'} kt-margin-b-20-tablet-and-mobile`} >
              <div className="kt-form__group kt-form__group--inline ">
                <div className="kt-form__label"> <label htmlFor="statusFilter"> Status:</label> </div>
                <div className="kt-form__control">
                  <select
                    id="statusFilter" 
                    value={currentStatus}  
                    className="form-control kt-selectpicker"  
                    onChange={({ target }) => { setCurrentStatus(target.value) }}
                  >
                    <option value='All'      > All     </option>
                    <option value='Active'   > Active   </option>
                    <option value='Moderate' > Moderate </option>
                    <option value='Cold'     > Cold     </option>
                    <option value='Inactive' > Inactive </option>
                  </select>
                </div>
              </div>
            </div>
            <div className={`col-md-${currentPeriod.id == 4 ? '2' : '4'} kt-margin-b-20-tablet-and-mobile`}>
              <div className="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Users..."
                  id="generalSearch"
                  onChange={({target}) => setSearchTerm( target.value)}>
                </input>
                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i className="la la-search"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {AllUsers.length > 0 ? (
        <div className="row">
          {selectedUser && (
            <RightSideDetails
              onCloseDrawer = {closeDrawerHandler}
              show={!!selectedUser}
              title="User Details">
              <div className="kt-portlet">
                <div className="kt-portlet__body">
                  <div className="kt-widget kt-widget--user-profile-3">
                    <div className="kt-widget__top">
                      {selectedUser.AgentCompanyLogo ?
                        <div className="kt-widget__pic kt-widget__pic--brand">
                          <div className="kt-media kt-media--xl kt-media--circle">
														<img src={selectedUser.AgentCompanyLogo} alt="image"/>
													</div>
                        </div>
                      :<div className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest">
                          {selectedUser.AgentCompanyName && selectedUser.AgentCompanyName.slice(0,1)}
                        </div>
                      }
                      <div className="kt-widget__content">
                        <div className="row align-items-center">
                          <div className="col-5">
                            <div className="kt-widget__head">
                              <span className="kt-widget__username">
                                {selectedUser.AgentCompanyName}
                              </span>
                            </div>
                            <div className="kt-widget__subhead padding-t0 padding-b0">
                              <span className="padding-r10">
                                <i className="flaticon2-new-email"></i>
                                {selectedUser.Email}
                              </span>
                              {selectedUser.Phone && 
                                <span className="padding-r10">
                                  <i className="flaticon2-phone"></i>
                                  {selectedUser.Phone}
                                </span>
                              }
                            </div>
                            <div className="kt-widget__subhead padding-t0 padding-b0">
                              { selectedUser. StateName && 
                                <span className="padding-r10">
                                  <i className="flaticon-placeholder-3"></i>
                                  {`${selectedUser.CityName}, ${selectedUser.StateName}`}
                                </span>
                              }
                              
                            </div>
                          </div>
                          <div className="col-4">
                            {selectedUser.GstNo && (
                              <div className="kt-widget__subhead padding-t0 padding-b0">
                                <span className="padding-r10">
                                  <i className="fa fa-money-check"> </i>
                                 {` Gst No : ${selectedUser.GstNo}`}
                                </span>
                              </div>
                            )}
                            {selectedUser.EmployeeStrength && (<div className="kt-widget__subhead padding-t0 padding-b0">
                              <span className="padding-r10">
                                <i className="fa fa-id-card"> </i> 
                                {` Employee Strength : ${selectedUser.EmployeeStrength}`}
                              </span>
                            </div>)}
                            {selectedUser.Member && (<div className="kt-widget__subhead padding-t0 padding-b0">
                              <span className="padding-r10">
                                <i className="fa fa-users"> </i> 
                               {` Members : ${selectedUser.Member.length}`}
                              </span>
                            </div>)}
                          </div>
                          <div className="col-3">
                            <div className = "block-badge mr-1 badge display-inline padding-lr20 padding-tb5 margin-l0 badge-secondary">
                              <span className=''>{selectedUser.ReferralCode}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="kt-portlet kt-prtlet--mobile">
                <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                  <div className="kt-portlet__head-label">
                    <nav className="file-nav navbar navbar-top">
                      <a className="top-nav-active" href="#Users">Users</a>
                    </nav>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                    <div className="kt-portlet__head-wrapper">
                      <div className="kt-portlet__head-actions">
                        <div className = "kt-portlet__head_subhead " >
                            <span>
                              <svg width="22px" height="20px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"></rect>
                                  <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                  <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                  <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                  <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                </g>
                              </svg>
                            </span>
                          <span className="ml-2" style={{verticalAlign:'bottom'}}>
                            Policies : <strong> {selectedUser.PolicyCount} </strong>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <UserTable  users={selectedUser.Member} />                    
                </div>
              </div>
            </RightSideDetails>
          )}
          <div className="col-sm-12">
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
              <table className="kt-datatable__table">
                <thead
                  className="kt-datatable__head"
                  style={{ display: "block" }}>
                  <tr className="kt-datatable__row block-row">
                    <th className="kt-datatable__cell text-clip" width="25%">
                      <span>Company Name</span>
                    </th>
                    <th className="kt-datatable__cell text-clip" width="11%">
                      <span>Contact</span>
                    </th>
                    <th className="kt-datatable__cell text-clip" width="15%">
                      <span>Location</span>
                    </th>
                    <th className="kt-datatable__cell kt-datatable__cell--center text-clip" width="17%">
                      <span>Registered On</span>
                    </th>
                    <th className="kt-datatable__cell kt-datatable__cell--center  text-clip" width="12%">
                      <span> Last Active </span>
                    </th>
                    <th className="kt-datatable__cell kt-datatable__cell--center text-clip" width="10%">
                      <span>Promo Code</span>
                    </th>
                    <th className="kt-datatable__cell kt-datatable__cell--center text-clip" width="10%">
                      <span>Members</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable mb-1"
              onScroll={helper.setStickyHeader}>
              <table className="kt-datatable__table table-striped">
                <tbody
                  className="kt-datatable__body"
                  style={{ display: "block" }}>
                  {AllUsers.map((u, i) => {
                    return (
                      <tr
                        key={i}
                        className="kt-datatable__row block-row clickable"
                        onClick={() => {selectUserHandler(u)}}>
                        <td
                          className="kt-datatable__cell text-clip"
                          width="25%"
                          title={u.AgentCompanyName}>
                            <div className="kt-user-card-v2">
                              <div className="kt-user-card-v2__pic">
                                {u.AgentCompanyLogo ?
                                    <img src={u.AgentCompanyLogo} alt="image"/>
                                  :
                                  <div className="kt-badge kt-badge--xl kt-badge--info">
                                    {u.AgentCompanyName.slice(0, 1)}
                                  </div>
                                }
                              </div>
                              <div className="kt-user-card-v2__details text-clip">
                                <span className="kt-user-card-v2__name text-clip">
                                  {u.AgentCompanyName}
                                </span>
                                {u.Email && (
                                <span className="kt-user-card-v2__desc text-clip" >
                                {u.Email.toLowerCase()}
                                </span>
                              )}
                              </div>
                            </div>
                        </td>
                        <td
                          className="kt-datatable__cell text-clip"
                          width="11%"
                          title= {u.Phone}>
                          <span > {u.Phone}</span>
                        </td>
                        <td
                          className="kt-datatable__cell text-clip"
                          width="15%"
                          title={`${u.CityName} ${u.StateName} `}>
                          <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__details text-clip">
                              <span className="kt-user-card-v2__name location-details text-clip  ">
                                {u.CityName}
                              </span>
                              <span className="kt-user-card-v2__desc text-clip text-dark" >
                                {u.StateName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td
                          className="kt-datatable__cell--center kt-datatable__cell text-clip"
                          width="17%"
                          title= {`${moment(u.CreatedDate).format('DD/MM/YYYY')} - ${moment(u.CreatedDate).format("hh:mm A")}`}>
                          <span > {`${moment(u.CreatedDate).format('DD/MM/YYYY')} - ${moment(u.CreatedDate).format("hh:mm A")}`}</span>
                        </td>
                        <td
                          className="kt-datatable__cell--center kt-datatable__cell text-clip"
                          width="12%"
                        >
                          <span className={`block-badge badge text-white font-weight-normal  badge-${u.CompanyLastActive && helper.getFormatedDateWithStatus(u.CompanyLastActive).badgeColor} mx-2`}> 
                            { u.CompanyLastActive && helper.getFormatedDateWithStatus(u.CompanyLastActive).dateString}
                          </span>
                        </td>
                        <td
                          className="kt-datatable__cell--center kt-datatable__cell text-clip"
                          width="10%"
                          title={u.ReferralCode}>
                            <span className="font-weight-500">
                              {u.ReferralCode}
                            </span>
                        </td>
                        <td
                          className="kt-datatable__cell--center kt-datatable__cell text-clip"
                          width="10%"
                        >
                          <span>{(u.Member && u.Member.length) || 0}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded margin-b0" 
              style={{position: 'sticky', left: '0', bottom: '0'}}>
              <table className="kt-datatable__table">
                <tbody
                  className="kt-datatable__body"
                  style={{ display: 'block', minHeight: '5vh' }}>
                  <tr
                    className="kt-datatable__row block-row"> 
                    <td className="kt-datatable__cell kt-font-bold footer-font text-right" width="67%"></td>
                    <td className="kt-datatable__cell kt-font-bold footer-font text-right" width="18%">Active Compaines :  {activeCompanies}</td>
                    <td className="kt-datatable__cell kt-font-bold footer-font text-right" width="15%">Active Users : {activeUsers}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="padding-20" style={{ margin:'auto'}}>
            <div className="empty-image-container">
                <img style={{ width: '80%' }} src={noClient} alt="no-client-img" />
                <h4 className="margin-t20" style={{ fontSize :'18px' }}> No Users Found </h4>
            </div>
        </div>
      )}
    </div>
  );

    
}

export default UserStatsTable;